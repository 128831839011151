// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';

// core components
import SimpleHeader from 'components/Headers/SimpleHeader';
import { constants } from 'variables';

const Typography = () => {
  return (
    <>
      <SimpleHeader name="Typography" parentName="Components" />
      <Container className="mt--6" fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper" lg="8">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Headings</h3>
              </CardHeader>
              <CardBody>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Heading 1</small>
                  </Col>
                  <Col sm="9">
                    <h1 className="mb-0">{constants.APP_NAME}</h1>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Heading 2</small>
                  </Col>
                  <Col sm="9">
                    <h2 className="mb-0">{constants.APP_NAME}</h2>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Heading 3</small>
                  </Col>
                  <Col sm="9">
                    <h3 className="mb-0">{constants.APP_NAME}</h3>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Heading 4</small>
                  </Col>
                  <Col sm="9">
                    <h4 className="mb-0">{constants.APP_NAME}</h4>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Heading 5</small>
                  </Col>
                  <Col sm="9">
                    <h5 className="mb-0">{constants.APP_NAME}</h5>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Heading 6</small>
                  </Col>
                  <Col sm="9">
                    <h6 className="mb-0">{constants.APP_NAME}</h6>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h3 className="mb-0">Display titles</h3>
              </CardHeader>
              <CardBody>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Display 1</small>
                  </Col>
                  <Col sm="9">
                    <h1 className="display-1 mb-0">{constants.APP_NAME}</h1>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Display 2</small>
                  </Col>
                  <Col sm="9">
                    <h2 className="display-2 mb-0">{constants.APP_NAME}</h2>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Display 3</small>
                  </Col>
                  <Col sm="9">
                    <h3 className="display-3 mb-0">{constants.APP_NAME}</h3>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Display 4</small>
                  </Col>
                  <Col sm="9">
                    <h4 className="display-4 mb-0">{constants.APP_NAME}</h4>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h3 className="mb-0">Specialized titles</h3>
              </CardHeader>
              <CardBody>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Heading</small>
                  </Col>
                  <Col sm="9">
                    <h3 className="heading mb-0">{constants.APP_NAME}</h3>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Heading title</small>
                  </Col>
                  <Col sm="9">
                    <h3 className="heading-title text-info mb-0">{constants.APP_NAME}</h3>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Heading seaction</small>
                  </Col>
                  <Col sm="9">
                    <div>
                      <h2 className="display-3">Header with small subtitle</h2>
                      <p className="lead text-muted">
                        According to the National Oceanic and Atmospheric Administration, Ted, Scambos, NSIDClead
                        scentist, puts the potentially record maximum.
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h3 className="mb-0">Paragraphs</h3>
              </CardHeader>
              <CardBody>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Paragraph</small>
                  </Col>
                  <Col sm="9">
                    <p>
                      I will be the leader of a company that ends up being worth billions of dollars, because I got the
                      answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to
                      push possibilities, to show people, this is the level that things could be at.
                    </p>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Lead text</small>
                  </Col>
                  <Col sm="9">
                    <p className="lead">
                      I will be the leader of a company that ends up being worth billions of dollars, because I got the
                      answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to
                      push possibilities, to show people, this is the level that things could be at.
                    </p>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Quote</small>
                  </Col>
                  <Col sm="9">
                    <blockquote className="blockquote">
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
                      </p>
                      <footer className="blockquote-footer">
                        Someone famous in <cite title="Source Title">Source Title</cite>
                      </footer>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Muted text</small>
                  </Col>
                  <Col sm="9">
                    <p className="text-muted mb-0">
                      I will be the leader of a company that ends up being worth billions of dollars, because I got the
                      answers...
                    </p>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Primary text</small>
                  </Col>
                  <Col sm="9">
                    <p className="text-primary">
                      I will be the leader of a company that ends up being worth billions of dollars, because I got the
                      answers...
                    </p>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Info text</small>
                  </Col>
                  <Col sm="9">
                    <p className="text-info mb-0">
                      I will be the leader of a company that ends up being worth billions of dollars, because I got the
                      answers...
                    </p>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Success text</small>
                  </Col>
                  <Col sm="9">
                    <p className="text-success mb-0">
                      I will be the leader of a company that ends up being worth billions of dollars, because I got the
                      answers...
                    </p>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Warning text</small>
                  </Col>
                  <Col sm="9">
                    <p className="text-warning mb-0">
                      I will be the leader of a company that ends up being worth billions of dollars, because I got the
                      answers...
                    </p>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col sm="3">
                    <small className="text-uppercase text-muted font-weight-bold">Danger text</small>
                  </Col>
                  <Col sm="9">
                    <p className="text-danger mb-0">
                      I will be the leader of a company that ends up being worth billions of dollars, because I got the
                      answers...
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Typography;
