import React from 'react';
import { Button, Card, CardBody, Container, Form, Row } from 'reactstrap';

import { usePermissions } from '../Permissions/usePermissions';
import EditInputDisplay from '../UserProfile/shared/EditInputDisplay';

import { useCreateRole } from './useCreateRole';

import { EmbeddedAlert } from 'components/Alerts';
import { AlertType } from 'components/Alerts/EmbeddedAlert';
import AdminHeader from 'components/Headers/AdminHeader';
import { CreateRoleData } from 'types/user';
import { pageRoutes } from 'variables';

const defaultCreateRole = {
  name: '',
  description: '',
  permissions: [],
};
const CreateRole = () => {
  const [createRole, setCreateRole] = React.useState<CreateRoleData>(defaultCreateRole);
  const [createRoleError, setCreateRoleError] = React.useState<string>('');

  const { permissions } = usePermissions();
  const { handleSubmit, loading, error } = useCreateRole();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id?: string) => {
    const { name, value } = e.target;
    if (name === 'permissions' && typeof id === 'string') {
      if (createRole.permissions.includes(id)) {
        setCreateRole({ ...createRole, [name]: createRole.permissions.filter((i) => i !== id) });
      } else {
        setCreateRole({ ...createRole, [name]: [...createRole.permissions, id] });
      }
    } else {
      setCreateRole({ ...createRole, [name]: value });
    }
  };

  const handleRoleSubmit = (payload: CreateRoleData) => {
    setCreateRoleError('');
    if (!Object.values(payload).every(Boolean)) {
      setCreateRoleError('Fields Not completely Filled!');
      return;
    }
    handleSubmit(payload);
  };
  return (
    <>
      <AdminHeader pageTitle="Create Role" previousPageTitle="Roles" previousPageLink={pageRoutes.ALL_ROLES} />
      <Container className="mt--6">
        {(error || createRoleError) && <EmbeddedAlert text={error || createRoleError} variant={AlertType.Danger} />}
        <Card>
          <CardBody className="d-flex justify-content-center flex-column">
            <Form>
              <Row>
                <EditInputDisplay
                  handleChange={handleChange}
                  label="name"
                  value={createRole.name}
                  name="name"
                  type="text"
                />
                <EditInputDisplay
                  handleChange={handleChange}
                  label="description"
                  value={createRole.description}
                  name="description"
                  type="text"
                />
              </Row>
              <div className="mt-2 mb-5">
                <label>
                  <strong>Permissions</strong>
                </label>
                {permissions?.length &&
                  permissions.map((i) => (
                    <div className="custom-control custom-checkbox custom-checkbox-success mb-3 mt-3" key={i.id}>
                      <input
                        className="custom-control-input"
                        id={i.id}
                        type="checkbox"
                        name="permissions"
                        onChange={(e) => handleChange(e, i.id)}
                      />
                      <label className="custom-control-label" htmlFor={i.id}>
                        {i.name}
                      </label>
                    </div>
                  ))}
              </div>

              <Button
                color="primary"
                type="button"
                size="md"
                style={{ width: '200px' }}
                disabled={loading}
                onClick={() => handleRoleSubmit(createRole)}
              >
                {loading ? 'Submit ...' : 'Submit'}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default CreateRole;
