import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';

import { useToastAlert } from 'components/Alerts';
import { userService } from 'services';
import { GeneralErrorResponse } from 'types/auth';
import { SetUserRole } from 'types/user';
import { apiUtils } from 'utils';

interface UseSetUserRoleProps {
  onSuccess?: () => void;
}

export const useSetUserRole = ({ onSuccess }: UseSetUserRoleProps) => {
  const [error, setError] = useState<string>('');
  const { triggerSuccessAlert } = useToastAlert();

  const SetUserRoleMutate = useMutation(userService.setUserRole, {
    onSuccess: () => {
      triggerSuccessAlert({ title: 'Role changed successfully!' });
      onSuccess?.();
    },
    onError: (error: AxiosError<GeneralErrorResponse>) => {
      const { message } = { ...error.response?.data };
      setError(apiUtils.getAPIErrorMessage(message));
    },
  });

  const handleSubmit = (payload: SetUserRole, id: string) => {
    setError('');
    const data = {
      payload,
      id,
    };
    SetUserRoleMutate.mutate(data);
  };

  return {
    handleSubmit,
    loading: SetUserRoleMutate.isLoading,
    error,
    success: SetUserRoleMutate.isSuccess,
  };
};
