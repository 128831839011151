import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';

import { OverviewResponseData } from 'views/pages/Admin/Dashboard/useGetOverviewData';

interface CardsHeaderProps {
  name: string;
  parentName: string;
  data?: OverviewResponseData['data'];
  loading?: boolean;
}

const CardsHeader: React.FC<CardsHeaderProps> = ({ name, parentName, data, loading }) => {
  return (
    <>
      <div className="header bg-dark-moss-green pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{' '}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>

            <Row>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          Members
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{data?.totalMembers}</span>
                        {loading && <Skeleton count={1} height={10} className="mt-3" />}
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <i className="fa fa-user" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          Families
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{data?.totalFamilies}</span>
                        {loading && <Skeleton count={1} height={10} className="mt-3" />}
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="fa fa fa-users" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          Ministries
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{data?.totalMinistries}</span>
                        {loading && <Skeleton count={1} height={10} className="mt-3" />}
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="fa fa-hand" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          Zones
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{data?.totalZones}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                          <i className="fa fa-location-dot" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CardsHeader;
