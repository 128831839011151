/* eslint-disable indent */

import React from 'react';
import { useLocation, Switch, Redirect } from 'react-router-dom';

import { PermissionsProvider } from 'components/ProtectedView/PermissionsProvider';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Sidebar from 'components/Sidebar/Sidebar';
import routes from 'routes';
import { BRAND_IMAGES } from 'assets/img/brand';
import { Layout } from 'types/index';
import { AuthenticatedRoute } from 'components/AuthenticatedRoute';

const Admin = () => {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === Layout.Admin) {
        return <AuthenticatedRoute path={prop.layout + prop.path} component={prop.component} key={key} exact />;
      } else {
        return null;
      }
    });
  };
  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.includes(routes[i].layout + routes[i].path)) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };

  /**
   * Closes side nav by default on mobile
   */
  const closeSideNavOnMobile = (e) => {
    document.body.classList.remove('g-sidenav-pinned');
    document.body.classList.add('g-sidenav-hidden');
    setSidenavOpen(false);
  };

  React.useEffect(() => {
    if (window.innerWidth < 1200) {
      closeSideNavOnMobile();
    }
  }, []);
  //

  const getNavbarTheme = () => {
    return !location.pathname.includes('admin/alternative-dashboard') ? 'dark' : 'light';
  };

  return (
    <>
      <PermissionsProvider>
        <Sidebar
          routes={routes}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          logo={{
            innerLink: '/',
            imgSrc: BRAND_IMAGES.logoDefault,
            imgAlt: '...',
          }}
        />
        <div className="main-content" ref={mainContentRef}>
          <AdminNavbar
            theme={getNavbarTheme()}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            brandText={getBrandText(location.pathname)}
          />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
          {/* <AdminFooter /> */}
        </div>
        {sidenavOpen ? <div className="backdrop d-xl-none" onClick={toggleSidenav} /> : null}
      </PermissionsProvider>
    </>
  );
};

export default Admin;
