import { Button, Card, CardBody, CardHeader, Form, FormGroup, Modal } from 'reactstrap';
import { useState } from 'react';

import { CreateConfigTypes, useCreateConfig } from './hooks/useCreateConfig';

import { FormInput } from 'components/Forms';
import { ModalCloseButton } from 'components/Buttons';

interface CreateModalProps {
  open: boolean;
  setOpenModal: (e: boolean) => void;
  configType: CreateConfigTypes;
}

const CreateConfigModal: React.FC<CreateModalProps> = ({ open, setOpenModal, configType }) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const { loading, handleSubmit } = useCreateConfig({
    configType,
    onSuccess: () => {
      setOpenModal(false);
    },
  });

  return (
    <>
      <Modal className="modal-dialog-centered" size="sm" isOpen={open} toggle={() => setOpenModal(false)}>
        <div className="modal-body p-0">
          <Card className="border-0 mb-0">
            <CardHeader>
              <h3 className="text-center d-inline">{`Create ${configType}`}</h3>
              <ModalCloseButton onClick={setOpenModal} />
            </CardHeader>
            <CardBody>
              <Form role="form">
                <FormGroup>
                  <FormInput
                    handleChange={(e) => setName(e.target.value)}
                    type="text"
                    name="name"
                    label="Name"
                    block
                    value={name}
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    handleChange={(e) => setDescription(e.target.value)}
                    type="text"
                    name="description"
                    label="Description"
                    block
                    value={description}
                  />
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={loading || !name}
                    onClick={() => handleSubmit({ name, description })}
                  >
                    {loading ? 'Submit...' : 'Submit'}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default CreateConfigModal;
