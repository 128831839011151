import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { userService } from 'services';
import { GetUserResponse, GetUserError } from 'types/user';
import { queryKeys } from 'variables';

export const useGetLoggedInUserInfo = () => {
  const { data, error, isLoading, refetch } = useQuery<AxiosResponse<GetUserResponse>, AxiosError<GetUserError>>(
    [queryKeys.GET_LOGGED_IN_USER],
    () => userService.getLoggedInUserInfo(),
    {
      cacheTime: 0,
    },
  );

  return {
    user: data?.data.data,
    loading: isLoading,
    refetch: async () => {
      await refetch();
    },
    error,
  };
};
