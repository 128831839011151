import { FormInput } from 'components/Forms';

interface SearchProps {
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Search: React.FC<SearchProps> = ({ label, onChange }) => {
  return (
    <FormInput
      block
      type="text"
      name="search"
      label={label || 'Search'}
      handleChange={onChange}
      placeholder="Enter search text"
    />
  );
};

export default Search;
