import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';

import { authService } from 'services';
import { LoginErrorResponse, LoginPayload, LoginResponse } from 'types/auth';
import { constants, pageRoutes } from 'variables';
import { apiUtils, routerUtils } from 'utils';

export const useLogin = () => {
  const [error, setError] = useState<string>('');

  const loginMutate = useMutation(authService.loginUser, {
    onSuccess: async (res: AxiosResponse<LoginResponse>) => {
      const { data } = res;
      localStorage.setItem(constants.AUTH_TOKEN, data.data.token);
      localStorage.setItem(constants.USER_INFO, JSON.stringify({ ...data.data, token: '' }));
      localStorage.setItem(constants.USER_PERMISSIONS, JSON.stringify(data.data.role?.permissions));

      const routerState = routerUtils.getRouterState();
      // Checks if user was trying to access a route before being logged out
      // @ts-expect-error
      if (routerState?.from?.pathname) {
        // Navigates to the previous route if any
        // @ts-expect-error
        routerUtils.push(routerState?.from?.pathname);
      } else {
        routerUtils.push(pageRoutes.DASHBOARD);
      }
    },
    onError: (error: AxiosError<LoginErrorResponse>) => {
      const { response } = error;
      setError(apiUtils.getAPIErrorMessage(response?.data.message));
    },
  });

  const handleSubmit = (payload: LoginPayload) => {
    setError('');
    loginMutate.mutate(payload);
  };

  return {
    handleSubmit,
    loading: loginMutate.isLoading,
    error,
  };
};
