import { apiUtils } from 'utils';
import { api } from 'variables';
import {
  CreateRoleData,
  UpdateRoleData,
  updateUserActivation,
  SetUserRole,
  SetStaffPassword,
  SetUserConfigs,
} from 'types/user';
import { UserData } from 'types/users';

const userService = {
  getAllUsers: async (params: string) => {
    return await apiUtils.getRequest(`${api.FETCH_ALL_USERS}?${params}`);
  },
  getAllRoles: async (params: string) => {
    return await apiUtils.getRequest(`${api.FETCH_ALL_ROLES}?${params}`);
  },
  getPermissions: async () => {
    return await apiUtils.getRequest(`${api.GET_PERMISSIONS}`);
  },
  getLoggedInUserInfo: async () => {
    return await apiUtils.getRequest(`${api.FETCH_LOGGED_IN_USER_INFO}`);
  },
  getUser: async (id: string) => {
    return await apiUtils.getRequest(`${api.FETCH_USER_INFO}${id}`);
  },
  getRole: async (id: string) => {
    return await apiUtils.getRequest(`${api.FETCH_ROLE_BY_ID}${id}`);
  },
  createRole: async (payload: CreateRoleData) => {
    return await apiUtils.postRequest(api.CREATE_ROLE, payload);
  },
  updateRole: async ({ payload, id }: { payload: UpdateRoleData; id: string }) => {
    return await apiUtils.putRequest(`${api.UPDATE_ROLE}${id}`, payload);
  },
  inviteMember: async (id: string) => {
    return await apiUtils.postRequest(`${api.INVITE_MEMBER}${id}`, {});
  },
  setUserRole: async ({ payload, id }: { payload: SetUserRole; id: string }) => {
    return await apiUtils.postRequest(`${api.SET_USER_ROLE}${id}`, payload);
  },
  setUserConfigs: async ({ payload, id }: { payload: SetUserConfigs; id: string }) => {
    return await apiUtils.postRequest(`${api.SET_USER_CONFIG}${id}`, payload);
  },
  updateUserActivation: async (payload: updateUserActivation) => {
    return await apiUtils.putRequest(api.CHANGE_USER_ACCOUNT_STATUS, payload);
  },
  setStaffPassword: async (payload: SetStaffPassword) => {
    return await apiUtils.postRequest(api.SET_NEW_PASSWORD, payload);
  },
  addMember: async (payload: UserData) => {
    return await apiUtils.postFormDataRequest(api.ADD_MEMBER, payload);
  },
  editMember: async ({ payload, id }: { payload: UserData; id: string }) => {
    return await apiUtils.postFormDataRequest(`${api.EDIT_MEMBER}${id}`, payload);
  },
  completeAccountSetup: async (payload: { password: string }) => {
    return await apiUtils.postRequest(api.COMPLETE_ACCOUNT_SETUP, payload);
  },
  getRandomBibleQuotes: async () => {
    return await apiUtils.getRequestWithFullAPIUrl(api.GET_RANDOM_API_QUOTES);
  },
  getOverviewData: async () => {
    return await apiUtils.getRequest(`${api.GET_OVERVIEW_DATA}`);
  },
  getUserIpAddress: async () => {
    return await apiUtils.getRequestWithFullAPIUrl(`${api.GET_USER_IP_ADDRESS}`);
  },
};

export default userService;
