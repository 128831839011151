import { ConfigData } from 'types/config';
import { Role, UserData } from 'types/users';

const dropdownUtils = {
  formatUsersDropdownOptions: (data?: UserData[]) => {
    if (!data) return [];
    return data.map(({ id, firstName, lastName }) => {
      return {
        value: id,
        label: `${firstName} ${lastName}`,
      };
    });
  },
  formatRolesDropdownOptions: (data?: Role[]) => {
    if (!data) return [];
    return data.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      };
    });
  },
  configWithIdValue: (data?: ConfigData[]) => {
    if (!data) return [];
    return data.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      };
    });
  },
  configWithNameValue: (data?: ConfigData[]) => {
    if (!data) return [];
    return data.map(({ name }) => {
      return {
        value: name,
        label: name,
      };
    });
  },
};

export default dropdownUtils;
