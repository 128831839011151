/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

import { useGetUserInfo } from './hooks/useGetUserInfo';
import SetRoleModal from './SetRoleModal';
import UserDetail from './UserDetail';
import UserConfigModal from './UserConfigModal';

import { pageRoutes } from 'variables';
import AdminHeader from 'components/Headers/AdminHeader';
import { authUtils } from 'utils';

const UserProfileView = () => {
  const { userId } = useParams();
  const userInfo = authUtils.getUserInfo();
  const { user, loading, refetch } = useGetUserInfo({ userId });

  const [openRoleModal, setOpenRoleModal] = useState<boolean>(false);
  const [openConfigModal, setOpenConfigModal] = useState<boolean>(false);

  const isLoggedInUser = userInfo?.id === user?.id;

  return (
    <>
      {openRoleModal && (
        <SetRoleModal
          open={openRoleModal}
          setOpenModal={setOpenRoleModal}
          id={user?.id as string}
          refetchUserDetails={refetch}
          defaultRole={user?.role?.id}
        />
      )}
      {openConfigModal && (
        <UserConfigModal
          open={openConfigModal}
          setOpenModal={setOpenConfigModal}
          id={user?.id as string}
          data={user}
          refetchUserDetails={refetch}
        />
      )}
      <AdminHeader pageTitle="User Details" previousPageLink={pageRoutes.ALL_USERS} previousPageTitle="All Users" />
      <Container className="mt--6">
        {loading ? (
          <Skeleton count={5} height={50} className="mt-3" />
        ) : (
          user && (
            <UserDetail
              showEditProfile
              showSendAccountInvite
              showSetRole
              showSetConfig
              user={user}
              isLoggedInUser={isLoggedInUser}
              setOpenRoleModal={() => setOpenRoleModal(true)}
              setOpenConfigModal={() => setOpenConfigModal(true)}
            />
          )
        )}
      </Container>
    </>
  );
};

export default UserProfileView;
