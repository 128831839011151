import React, { useState } from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';

interface ToastProviderProps {
  children: React.ReactNode;
}

interface TostTriggerProps {
  message: string;
  title?: string;
  buttonStyle?: string;
  success?: boolean;
  warning?: boolean;
  info?: boolean;
  custom?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
  showCancel?: boolean;
}

const defaultTriggerValue = (params: TostTriggerProps) => {};

export const ToastContext = React.createContext({
  triggerBasicAlert: defaultTriggerValue,
  triggerSuccessAlert: defaultTriggerValue,
  triggerInfoAlert: defaultTriggerValue,
  triggerWarningAlert: defaultTriggerValue,
  triggerQuestionAlert: defaultTriggerValue,
  closeAlert: () => {},
});

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [alert, setalert] = useState<React.ReactNode | null>(null);

  const triggerAlert = ({
    message,
    title,
    buttonStyle,
    success,
    warning,
    info,
    custom,
    onConfirm,
    confirmBtnText,
    cancelBtnText,
    showCancel,
  }: TostTriggerProps) => {
    setalert(
      <ReactBSAlert
        success={success ?? false}
        warning={warning ?? false}
        info={info ?? false}
        custom={custom ?? false}
        title={title ?? ''}
        confirmBtnText={confirmBtnText ?? 'Yes'}
        cancelBtnText={cancelBtnText ?? 'No'}
        // @ts-expect-error
        onConfirm={onConfirm ? () => onConfirm() : setalert(null)}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle={buttonStyle ?? 'success'}
        cancelBtnBsStyle="danger"
        showCancel={showCancel ?? true}
        customIcon={
          <div className="swal2-icon swal2-question swal2-animate-question-icon" style={{ display: 'flex' }}>
            <span className="swal2-icon-text">
              <i className="fa fa-question text-xl" />
            </span>
          </div>
        }
      >
        {message}
      </ReactBSAlert>,
    );
  };

  const triggerBasicAlert = (props: TostTriggerProps) => {
    triggerAlert(props);
  };

  const triggerSuccessAlert = (props: TostTriggerProps) => {
    triggerAlert({ ...props, success: true, buttonStyle: 'success' });
  };

  const triggerInfoAlert = (props: TostTriggerProps) => {
    triggerAlert({ ...props, info: true, buttonStyle: 'info' });
  };

  const triggerWarningAlert = (props: TostTriggerProps) => {
    triggerAlert({ ...props, warning: true, buttonStyle: 'warning' });
  };

  const triggerQuestionAlert = (props: TostTriggerProps) => {
    triggerAlert({ ...props, custom: true });
  };

  const closeAlert = () => {
    setalert(null);
  };

  return (
    <ToastContext.Provider
      value={{
        triggerBasicAlert,
        triggerSuccessAlert,
        triggerInfoAlert,
        triggerWarningAlert,
        triggerQuestionAlert,
        closeAlert,
      }}
    >
      {alert}
      {children}
    </ToastContext.Provider>
  );
};
