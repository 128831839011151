import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

import { useGetUserInfo } from '../Users/hooks/useGetUserInfo';
import UserDetail from '../Users/UserDetail';

import { pageRoutes } from 'variables';
import AdminHeader from 'components/Headers/AdminHeader';
import { authUtils } from 'utils';

const DirectoryDetail = () => {
  const { userId } = useParams();
  const userInfo = authUtils.getUserInfo();
  const { user, loading } = useGetUserInfo({ userId });

  const isLoggedInUser = userInfo?.id === user?.id;

  return (
    <>
      <AdminHeader
        pageTitle={`${user?.firstName} ${user?.lastName}`}
        previousPageLink={pageRoutes.DIRECTORY}
        previousPageTitle="Directory"
      />
      <Container className="mt--6">
        {loading ? (
          <Skeleton count={5} height={50} className="mt-3" />
        ) : (
          user && <UserDetail user={user} isLoggedInUser={isLoggedInUser} showEditProfile={isLoggedInUser} />
        )}
      </Container>
    </>
  );
};

export default DirectoryDetail;
