import { Card, CardHeader, Table, Container, CardFooter } from 'reactstrap';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useGetAllUsers } from '../Users/hooks/useGetAllUsers';

import AdminHeader from 'components/Headers/AdminHeader';
import { DataTableError } from 'components/DataTableError';
import { pageRoutes } from 'variables';
import { routerUtils } from 'utils';
import { Pagination } from 'components/Pagination';
import { useFilterParams } from 'hooks/useFilterParams';
import { PageFilters } from 'components/PageFilter';
import { FilterEnum } from 'components/PageFilter/PageFilters';
import { ICON_IMAGES } from 'assets/img/icons';

const Directory = () => {
  const { params, paramsString, setParams } = useFilterParams();
  const { allUsers, error, loading, refetch, count, pages } = useGetAllUsers(paramsString);
  const [showFilter, setShowFilter] = useState(false);

  const addDefaultSrc = (ev: React.ChangeEvent<HTMLImageElement>) => {
    ev.target.src = ICON_IMAGES.defaultImage;
  };

  return (
    <>
      <AdminHeader pageTitle="Directory" />
      <Container className="mt--6" fluid>
        <>
          {showFilter && (
            <PageFilters
              filters={[
                FilterEnum.USERS,
                FilterEnum.SEARCH_INPUT,
                FilterEnum.VERIFICATION_STATUS,
                FilterEnum.IS_SUPERUSER,
              ]}
              setParams={setParams}
              setShowPageFilter={() => setShowFilter(!showFilter)}
            />
          )}
        </>
        <Card>
          <CardHeader className="border-0">
            <DataTableError
              isEmptyError={!allUsers?.length}
              error={error?.message}
              isRefreshing={loading}
              onRefresh={refetch}
            />
            {loading && <Skeleton count={5} height={50} className="mt-3" />}
          </CardHeader>
          {!!allUsers?.length && (
            <Table className="align-items-center table-flush" responsive striped hover>
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>City</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.map((i) => {
                  return (
                    <tr
                      key={i.id}
                      className="cursor-hover"
                      onClick={() => routerUtils.push(`${pageRoutes.DIRECTORY_DETAIL}${i.id}`)}
                    >
                      <td className="table-user text-capitalize">
                        <img
                          alt="."
                          className="avatar rounded-circle mr-3 border"
                          src={String(i.profileImage) || ICON_IMAGES.defaultImage}
                          style={{ objectFit: 'cover' }}
                          onError={addDefaultSrc}
                        />
                        <b>{`${i.firstName} ${i.lastName}`}</b>
                      </td>
                      <td>{i?.membershipInfo?.phoneNumber || 'N/A'}</td>
                      <td>{i.email}</td>
                      <td>{i?.membershipInfo?.city || 'N/A'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          {pages && count && pages > 1 && (
            <CardFooter>
              <Pagination
                totalCount={count}
                pageCount={pages}
                onChange={(page) => setParams({ page })}
                page={params.page}
              />
            </CardFooter>
          )}
        </Card>
      </Container>
    </>
  );
};

export default Directory;
