/* eslint-disable @typescript-eslint/no-misused-promises */
import { Button, Card, CardBody, CardHeader, Form, Modal } from 'reactstrap';
import { useForm } from 'react-hook-form';

import { useGetConfigs } from '../Config/hooks/useGetConfigs';
import { CreateConfigTypes } from '../Config/hooks/useCreateConfig';

import { useSetUserConfig } from './hooks/useSetUserConfig';

import { ModalCloseButton } from 'components/Buttons';
import { FormSelectInput } from 'components/Forms';
import { dropdownOptions } from 'variables';
import { UserData } from 'types/users';
import { SetUserConfigs } from 'types/user';

const officeMapping = {
  isElder: 'ELDER',
  isDeacon: 'DEACON',
  isPreacher: 'PREACHER',
};

interface CreateModalProps {
  open: boolean;
  setOpenModal: (e: boolean) => void;
  id: string;
  data?: UserData;
  refetchUserDetails?: () => void;
}

const UserConfigModal: React.FC<CreateModalProps> = ({ open, setOpenModal, id, data, refetchUserDetails }) => {
  const { dataDropdownOptionsIdValue: families } = useGetConfigs(CreateConfigTypes.FAMILY);
  const { dataDropdownOptionsIdValue: zones } = useGetConfigs(CreateConfigTypes.ZONE);

  const { isElder, isDeacon, isPreacher, assignedFamily, assignedMinistry, assignedZone } = data || {};
  const { loading, handleSubmit } = useSetUserConfig({
    onSuccess: () => {
      setOpenModal(false);
      refetchUserDetails?.();
    },
  });

  const onSubmit = (data: SetUserConfigs) => {
    const { office } = data;
    data.isDeacon = Boolean(office === officeMapping.isDeacon);
    data.isElder = Boolean(office === officeMapping.isElder);
    data.isPreacher = Boolean(office === officeMapping.isPreacher);
    delete data.office;
    console.log({ data });

    handleSubmit(data, id);
  };

  const defaultOffice = officeMapping[isElder ? 'isElder' : isDeacon ? 'isDeacon' : isPreacher ? 'isPreacher' : ''];

  const {
    handleSubmit: handleFormSubmit,
    control,
    // formState: { errors },
  } = useForm<SetUserConfigs>({
    defaultValues: {
      isElder,
      isDeacon,
      isPreacher,
      assignedFamily,
      assignedMinistry,
      assignedZone,
      office: defaultOffice,
    },
  });

  // @ts-expect-error
  const defaultFamily = families?.find((i) => i.value === assignedFamily?.id);
  // @ts-expect-error
  const defaultZone = zones?.find((i) => i.value === assignedZone?.id);

  return (
    <>
      <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={() => setOpenModal(false)}>
        <div className="modal-body p-0">
          <Card className="border-0 mb-0">
            <CardHeader>
              <h3 className="text-center d-inline">Configure Membership</h3>
              <ModalCloseButton onClick={setOpenModal} />
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleFormSubmit(onSubmit)}>
                <FormSelectInput
                  control={control}
                  block
                  options={families}
                  name="assignedFamily"
                  label="Family"
                  defaultValue={defaultFamily}
                />
                <FormSelectInput
                  control={control}
                  block
                  options={zones}
                  name="assignedZone"
                  label="Zone"
                  defaultValue={defaultZone}
                />
                <hr />
                <FormSelectInput
                  control={control}
                  block
                  options={dropdownOptions.OFFICE_OCCUPIED}
                  name="office"
                  label="Office"
                  defaultValue={dropdownOptions.OFFICE_OCCUPIED.find((i) => i.value === defaultOffice)}
                />
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit" disabled={loading}>
                    {loading ? 'Saving...' : 'Save'}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default UserConfigModal;
