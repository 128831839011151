const queryKeys = {
  ALL_USERS: 'allUsers',
  ALL_AUDIT_LOGS: 'allAuditLogs',
  GET_LOGGED_IN_USER: 'getLoggedInUser',
  GET_USER: 'getUser',
  ALL_ROLES: 'allRoles',
  GET_ROLE: 'getRole',
  GET_PERMISSIONS: 'getPermissions',
  GET_MINISTRIES: 'getMinistries',
  GET_ZONES: 'getZones',
  GET_FAMILIES: 'getFamilies',
  GET_BIBLE_QUOTE: 'getBibleQuote',
  GET_OVERVIEW_DATA: 'getOverviewData',
  GET_USER_IP: 'getUserIP',
};

export default queryKeys;
