import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import ViewProfileImageModal from './ViewProfileImageModal';
import { useAccountSetupInvite } from './hooks/useAccountSetupInvite';

import { UserData } from 'types/users';
import { ICON_IMAGES } from 'assets/img/icons';
import { routerUtils } from 'utils';
import { pageRoutes } from 'variables';
import { useSweetToastAlert } from 'components/Alerts';
import useGetUserPermissionConfig from 'components/ProtectedView/useGetUserPermissionConfig';
import { ProtectedView } from 'components/ProtectedView/ProtectedView';

interface UserDetailProp {
  setOpenRoleModal?: () => void;
  setOpenConfigModal?: () => void;
  user: UserData;
  showEditProfile?: boolean;
  showSetRole?: boolean;
  showSendAccountInvite?: boolean;
  isLoggedInUser?: boolean;
  showSetConfig?: boolean;
}

const officialText = {
  isElder: 'ELDER',
  isDeacon: 'DEACON',
  isPreacher: 'PREACHER',
};

const UserDetail: React.FC<UserDetailProp> = ({
  user,
  showEditProfile,
  showSetRole,
  showSendAccountInvite,
  setOpenRoleModal,
  isLoggedInUser,
  showSetConfig,
  setOpenConfigModal,
}) => {
  const { codes } = useGetUserPermissionConfig();

  const { handleSubmit } = useAccountSetupInvite({
    onSuccess: () => {
      closeAlert();
    },
  });
  const { triggerQuestionAlert, closeAlert } = useSweetToastAlert();
  const handleQuestionTriggerAlert = () => {
    triggerQuestionAlert({
      title: 'Send invitation to setup account',
      message: `Are you sure you want to send an invitation to ${user.firstName} ?`,
      onConfirm: () => {
        handleSubmit(user.id);
        closeAlert();
      },
    });
  };

  const InfoText: React.FC<{ text: any; label: string; noCapitalize?: boolean; isList?: boolean }> = ({
    text,
    label,
    noCapitalize,
    isList,
  }) => {
    return (
      <Col md="6">
        <p className={`${noCapitalize ? '' : 'text-capitalize'}`}>
          <strong className="text-gray-dark">{label}: </strong>
          {isList
            ? text?.map((i: string, idx: string) => (
                <span key={idx} className="pr-2">
                  {i}
                </span>
              ))
            : text || 'N/A'}
        </p>
      </Col>
    );
  };

  const addDefaultSrc = (ev: React.ChangeEvent<HTMLImageElement>) => {
    ev.target.src = ICON_IMAGES.defaultImage;
  };

  return (
    <>
      <Row className="justify-content-center">
        <Card>
          <CardBody>
            <ViewProfileImageModal imageSrc={String(user?.profileImage)}>
              <img
                alt="profile image"
                className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                src={String(user?.profileImage) || ICON_IMAGES.defaultImage}
                style={{ width: '250px', height: '250px', objectFit: 'cover' }}
                onError={addDefaultSrc}
              />
            </ViewProfileImageModal>

            <div className="pt-4 text-center">
              <h5 className="h3 title">
                <span className="d-block mb-1">
                  {user.firstName} {user.lastName}
                </span>
              </h5>
            </div>
            {(user?.isDeacon || user?.isElder || user?.isPreacher) && (
              <Row className="justify-content-center align-items-center pt-2">
                <div style={{ width: '30px' }}>
                  <img src={ICON_IMAGES.officialBadge} className="img-fluid" />
                </div>
                {user?.isDeacon && <h4 className="pl-2 text-primary">{officialText.isDeacon}</h4>}
                {user?.isElder && <h4 className="pl-2 text-primary">{officialText.isElder}</h4>}
                {user?.isPreacher && <h4 className="pl-2 text-primary">{officialText.isPreacher}</h4>}
              </Row>
            )}
          </CardBody>
        </Card>
      </Row>
      <Card>
        <CardBody className="">
          <CardHeader>
            <Row className="justify-content-center align-items-center">
              {showEditProfile && (
                <ProtectedView permission={codes.CAN_EDIT_MEMBERS} permissionOveride={isLoggedInUser}>
                  <Col sm={12} md={'auto'}>
                    <Button
                      className="btn-round btn-icon mb-2"
                      color="primary"
                      id="create-member"
                      onClick={(e) =>
                        routerUtils.push(
                          isLoggedInUser ? pageRoutes.PROFILE_EDIT : `${pageRoutes.EDIT_MEMBER}${user.id}`,
                        )
                      }
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-edit" />
                      </span>
                      <span className="btn-inner--text">Edit profile</span>
                    </Button>
                  </Col>
                </ProtectedView>
              )}

              {showSetRole && (
                <ProtectedView permission={codes.CAN_SET_USER_ROLE}>
                  <Col sm={12} md={'auto'}>
                    <Button
                      className="btn-round btn-icon mb-2"
                      color="primary"
                      id="create-member"
                      onClick={setOpenRoleModal}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-user" />
                      </span>
                      <span className="btn-inner--text">Set role</span>
                    </Button>
                  </Col>
                </ProtectedView>
              )}

              {showSendAccountInvite && user.accountStatus !== 'ACTIVATED' && (
                <ProtectedView permission={codes.CAN_SEND_ACCOUNT_INVITE}>
                  <Col sm={12} md={'auto'}>
                    <Button
                      className="btn-round btn-icon mb-2"
                      color="primary"
                      id="create-member"
                      onClick={handleQuestionTriggerAlert}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-shield" />
                      </span>
                      <span className="btn-inner--text">{`${
                        user.accountStatus === 'PENDING' ? 'Send' : 'Resend'
                      } account invite`}</span>
                    </Button>
                  </Col>
                </ProtectedView>
              )}

              {showSetConfig && (
                <ProtectedView permission={codes.CAN_MODIFY_CONFIGURATIONS}>
                  <Col sm={12} md={'auto'}>
                    <Button
                      className="btn-round btn-icon mb-2"
                      color="primary"
                      id="create-member"
                      onClick={setOpenConfigModal}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-cogs" />
                      </span>
                      <span className="btn-inner--text">Configure Membership</span>
                    </Button>
                  </Col>
                </ProtectedView>
              )}
            </Row>
          </CardHeader>
          <Row className="pt-5">
            <InfoText text={user?.firstName} label="First name" />
            <InfoText text={user?.lastName} label="Last name" />
            <InfoText text={user?.membershipInfo?.otherNames} label="Other names" />
            <InfoText text={user?.email} noCapitalize label="Email address" />
            <InfoText text={user?.membershipInfo?.phoneNumber} label="Phone number" />
            <ProtectedView permission={codes.CAN_VIEW_MEMBER_DATE_OF_BIRTH} permissionOveride={isLoggedInUser}>
              <InfoText text={user?.membershipInfo?.dateOfBirth} label="Date of birth" />
            </ProtectedView>
          </Row>
          <hr />
          <Row>
            <InfoText text={user?.membershipInfo?.address} label="Address" />
            <InfoText text={user?.membershipInfo?.city} label="City" />
            <InfoText text={user?.membershipInfo?.postCode} label="Post Code" />
          </Row>
          <hr />
          <Row>
            <InfoText text={user?.membershipInfo?.gender} label="Gender" />
            <InfoText text={user?.membershipInfo?.maritalStatus} label="Marital status" />
            <InfoText text={user?.membershipInfo?.profession} label="Profession" />
            <InfoText text={user?.membershipInfo?.specialNeed} label="Special Need" />
          </Row>
          <ProtectedView permission={codes.CAN_VIEW_MEMBER_NEXT_OF_KIN} permissionOveride={isLoggedInUser}>
            <hr />
            <Row>
              <InfoText text={user?.membershipInfo?.nextOfKinFullName} label="Next of kin name" />
              <InfoText text={user?.membershipInfo?.nextOfKinRelationship} label="Next of kin relationship" />
              <InfoText text={user?.membershipInfo?.nextOfKinAddress} label="Next of kin address" />
              <InfoText text={user?.membershipInfo?.nextOfKinContact} label="Next of kin contact" />
            </Row>
          </ProtectedView>

          <hr />
          <Row>
            <ProtectedView permission={codes.CAN_VIEW_BAPTISM_INFORMATION} permissionOveride={isLoggedInUser}>
              <InfoText text={user?.membershipInfo?.dateOfBaptism} label="Date of baptism" />
              <InfoText text={user?.membershipInfo?.placeOfBaptism} label="Place of baptism" />
            </ProtectedView>
            <InfoText text={user?.membershipInfo?.ministriesOfInterests} label="ministries of interests" isList />
          </Row>

          {user?.role && (
            <>
              <hr />
              <Row>
                <InfoText text={user?.role?.name} label="System Assigned Role" />
              </Row>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default UserDetail;
