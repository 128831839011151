import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import SetNewPasswordForm from './SetNewPasswordForm';
import { useSetNewPassword } from './useSetNewPassword';

import { EmbeddedAlert } from 'components/Alerts';
import { AlertType } from 'components/Alerts/EmbeddedAlert';
import AuthHeader from 'components/Headers/AuthHeader';
import { pageRoutes } from 'variables/pageRoutes';

const SetNewPassword = () => {
  const { handleSubmit, loading, error } = useSetNewPassword();
  const [formError, setFormError] = useState('');

  return (
    <>
      <AuthHeader title="Set New Password" lead="Enter your new password below." />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            {(error || formError) && <EmbeddedAlert text={error || formError} variant={AlertType.Danger} />}
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <SetNewPasswordForm handleSubmit={handleSubmit} loading={loading} setFormError={setFormError} />
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link className="text-light" to={pageRoutes.LOGIN}>
                  <small>Sign in</small>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SetNewPassword;
