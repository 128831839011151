import React from 'react';

export enum Layout {
  Admin = '/admin',
  Auth = '/',
}

export interface CollapsibleRoute extends Route {}

export interface Route {
  path: string;
  name?: string;
  icon?: string;
  component?: () => React.ReactNode;
  layout?: Layout;
  collapse?: boolean;
  permission: string;
  state: string;
  miniName?: string;
  redirect?: string;
  views: CollapsibleRoute[];
}

export interface ViewRowDisplayProps {
  firstCol?: string;
  secondCol?: string;
  name: string;
  value: string;
  isImageSrc?: boolean;
}

export enum ImagePurpose {
  ProfileUpload = 'profile_upload',
  ProductImage = 'product_image',
  logo = 'product_logo',
}

export interface MultiplePostRequestData {
  url: string;
  payload: any;
}

export interface APISuccessResponse {
  success: boolean;
  next: string | null;
  previous: string | null;
  count: number;
  pages: number;
  message: string;
}

export interface APIErrorResponse {
  success: boolean;
  message: string;
}
