import React from 'react';

import { constants } from 'variables';
import { permissionsConfig, permissionCodes } from 'variables/permissions';

interface PermissionsProviderProps {
  children: React.ReactNode;
}

interface PermissionContextValue {
  userPermissions: { [key: string]: boolean };
  codes: typeof permissionCodes;
}

export const PermissionsContext = React.createContext<PermissionContextValue>({
  userPermissions: {},
  codes: permissionCodes,
});

export const PermissionsProvider: React.FC<PermissionsProviderProps> = ({ children }) => {
  const checkPermissionsInStorage = localStorage.getItem(constants.USER_PERMISSIONS);

  const permissionsInLocalStorage =
    checkPermissionsInStorage && checkPermissionsInStorage !== 'undefined'
      ? JSON.parse(checkPermissionsInStorage)
      : [{}];

  const comparePermissionArrays = (array1: any, array2: any) => {
    const result = {};
    if (!Array.isArray(array1) || !array1.length || !Array.isArray(array2) || !array2.length) {
      return result;
    }
    array1?.forEach((obj1: any) => {
      const matchingObj = array2?.find((obj2: any) => obj2.sysId === obj1.sysId && obj2.code === obj1.code);
      if (matchingObj) {
        result[matchingObj.code] = true;
      }
    });
    return result;
  };

  const userPermissions = comparePermissionArrays(permissionsConfig, permissionsInLocalStorage);

  return (
    <PermissionsContext.Provider value={{ userPermissions, codes: permissionCodes }}>
      {children}
    </PermissionsContext.Provider>
  );
};
