import { LocationDescriptor } from 'history';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Container, Row, Col } from 'reactstrap';

interface AdminHeaderProps {
  pageTitle?: string;
  previousPageTitle?: string;
  previousPageLink?: LocationDescriptor;
  onClickNew?: () => void;
  onClickFilters?: () => void;
  showFilters?: boolean;
}
const AdminHeader: React.FC<AdminHeaderProps> = ({
  pageTitle,
  previousPageTitle,
  previousPageLink,
  onClickFilters,
  onClickNew,
  showFilters,
}) => {
  return (
    <>
      <div className="header pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 d-inline-block mb-0">{pageTitle}</h6>
                <Breadcrumb className="d-none d-md-inline-block ml-md-4" listClassName="breadcrumb-links">
                  <BreadcrumbItem>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to="/admin/dashboard">Overview</Link>
                  </BreadcrumbItem>
                  {previousPageLink && previousPageTitle && (
                    <BreadcrumbItem>
                      <Link to={previousPageLink}>{previousPageTitle}</Link>
                    </BreadcrumbItem>
                  )}
                  <BreadcrumbItem aria-current="page" className="active">
                    {pageTitle}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="text-right" lg="6" xs="5">
                {onClickFilters && (
                  <Button className="btn-sm" color="success" onClick={onClickFilters}>
                    {showFilters ? <i className="fa fa-cancel" /> : <i className="fa fa-search" />}
                  </Button>
                )}
                {onClickNew && (
                  <Button className="btn-neutral" color="" href="#" onClick={onClickNew} size="sm">
                    New
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AdminHeader;
