import VerificationCodeInput, { AuthCodeRef } from 'react-auth-code-input';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

import { useVerifyForgotPassword } from './useVerifyForgotPassword';

import { AlertType } from 'components/Alerts/EmbeddedAlert';
import { EmbeddedAlert } from 'components/Alerts';
import AuthHeader from 'components/Headers/AuthHeader';
import { pageRoutes } from 'variables/pageRoutes';
import { useSearchQuery } from 'hooks/useSearchQuery';

const CODE_LENGTH = 6;

const VerifyForgotPassword = () => {
  const CodeInputRef = useRef<AuthCodeRef>(null);
  const query = useSearchQuery();
  const { handleSubmit, loading, error } = useVerifyForgotPassword();
  const [inputCode, setInputCode] = useState('');

  const email = query.get('email');

  const handleCodeChange = (code: string) => {
    setInputCode(code);
    if (code.length === CODE_LENGTH && email) {
      handleSubmit({ email, token: code });
    }
  };

  return (
    <>
      <AuthHeader
        title="Verification"
        lead="A message with a verification code has been sent to your email address. Enter the code to continue."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            {error && <EmbeddedAlert text={error} variant={AlertType.Danger} />}
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <VerificationCodeInput
                  onChange={handleCodeChange}
                  allowedCharacters="numeric"
                  autoFocus
                  ref={CodeInputRef}
                  length={CODE_LENGTH}
                  inputClassName="form-control form-control-lg col mx-1 text-center"
                  containerClassName="form-group form-row"
                />
                <div className="text-center">
                  <Button
                    className="my-2"
                    color="info"
                    type="button"
                    disabled={loading || inputCode.length < CODE_LENGTH}
                    onClick={() => email && handleSubmit({ email, token: inputCode })}
                  >
                    {loading ? 'Submitting...' : 'Submit'}
                  </Button>
                </div>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link className="text-light" to={pageRoutes.LOGIN}>
                  <small>Sign in</small>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VerifyForgotPassword;
