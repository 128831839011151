import './styles.css';

interface LoaderProps {
  loading: boolean;
  size?: string | number;
  noMargins?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ loading, size, noMargins }) =>
  loading ? (
    <div
      className="loader"
      style={{
        width: size ?? `${size}px`,
        height: size ?? `${size}px`,
        marginLeft: noMargins ? '0px' : '45%',
        marginTop: noMargins ? '0px' : '50px',
      }}
    />
  ) : null;

export default Loader;
