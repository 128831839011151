import { useState } from 'react';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import { SetNewPasswordPayload } from 'types/auth';

interface SetNewPasswordProps {
  loading: boolean;
  handleSubmit: (payload: SetNewPasswordPayload) => void;
  setFormError: (e: string) => void;
}

const SetNewPasswordForm: React.FC<SetNewPasswordProps> = ({ loading, handleSubmit, setFormError }) => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const handleSetNewPassword = () => {
    setFormError('');
    if (password !== confirmPassword) {
      setFormError('Passwords do not match!');
      return;
    }
    handleSubmit({ password });
  };

  const disableSubmit = loading || !password;

  return (
    <Form role="form">
      <FormGroup className="mb-3">
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputGroupAddon addonType="append" className="cursor-hover" onClick={() => setShowPassword(!showPassword)}>
            <InputGroupText>
              <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Confirm password"
            type={showConfirmPassword ? 'text' : 'password'}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <InputGroupAddon
            addonType="append"
            className="cursor-hover"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            <InputGroupText>
              <i className={showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
      <div className="text-center">
        <Button className="my-4" color="info" type="button" disabled={disableSubmit} onClick={handleSetNewPassword}>
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
    </Form>
  );
};

export default SetNewPasswordForm;
