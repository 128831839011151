const constants = {
  APP_OWNER: 'COC Stratford',
  APP_NAME: 'COC Stratford Admin App',
  AUTH_TOKEN: 'AUTH_TOKEN',
  RESET_PASSWORD_AUTH_TOKEN: 'RESET_PASSWORD_AUTH_TOKEN',
  USER_INFO: 'USER_INFO',
  USER_PERMISSIONS: 'USER_PERMISSIONS',
  V1_BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
  PAGE_SIZE: 20,
  ALL_PAGES: 999999,
  IP_ADDRESS: 'IP',
  TWELVE_HR_DATE_FMT: 'YYYY-MM-DD hh:mm A',
};

export default constants;
