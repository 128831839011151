import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { userService } from 'services';
import { queryKeys } from 'variables';

export const useGetRandomBibleQuotes = () => {
  const { data, error, isLoading, refetch } = useQuery<AxiosResponse<string>, AxiosError<any>>(
    [queryKeys.GET_BIBLE_QUOTE],
    () => userService.getRandomBibleQuotes(),
  );

  return {
    bibleQuote: data?.data,
    loading: isLoading,
    refetch: async () => {
      await refetch();
    },
    error,
  };
};
