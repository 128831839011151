import classnames from 'classnames';
import { useState } from 'react';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import { ForgotPasswordPayload } from 'types/auth';

interface ForgotPasswordProps {
  loading: boolean;
  handleSubmit: (payload: ForgotPasswordPayload) => void;
}

const ForgotPasswordForm: React.FC<ForgotPasswordProps> = ({ loading, handleSubmit }) => {
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [inputData, setFormInputData] = useState<ForgotPasswordPayload>({ email: '' });

  const handleTextChange = (key: string, value: string) => {
    setFormInputData((prev) => ({ ...prev, [key]: value }));
  };

  const disableSubmit = loading || !inputData.email;

  return (
    <Form role="form">
      <FormGroup
        className={classnames('mb-3', {
          focused: focusedEmail,
        })}
      >
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Email"
            type="email"
            onFocus={() => setfocusedEmail(true)}
            onBlur={() => setfocusedEmail(true)}
            onChange={(e) => handleTextChange('email', e.target.value)}
          />
        </InputGroup>
      </FormGroup>
      <div className="text-center">
        <Button
          className="my-4"
          color="info"
          type="button"
          disabled={disableSubmit}
          onClick={() => handleSubmit(inputData)}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
    </Form>
  );
};

export default ForgotPasswordForm;
