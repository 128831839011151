import authRoutes from './authRoutes';
import adminRoutes from './adminRoutes';

import devDocsRoutes from 'routes/devDocRoutes';

const routes = [
  ...authRoutes,
  ...adminRoutes,
  process.env.REACT_APP_SHOW_DEV_DOCS === 'true' ? { ...devDocsRoutes } : {},
];

export default routes;
