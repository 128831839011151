import { ViewAllAuditLogs } from 'views/pages/Admin/AuditLog';
import { Layout } from 'types/index';
import { Settings } from 'views/pages/Admin/Settings';
import { ViewAllUsers, ViewUserDetail } from 'views/pages/Admin/Users';
import { UserProfileEdit } from 'views/pages/Admin/UserProfile/UserProfileEdit';
import { UserProfileView } from 'views/pages/Admin/UserProfile/UserProfileView';
import { ViewAllRoles } from 'views/pages/Admin/Roles';
import { AddMember, EditMember } from 'views/pages/Admin/AddMember';
import CreateRole from 'views/pages/Admin/Roles/CreateRole';
import UpdateRole from 'views/pages/Admin/Roles/UpdateRole';
import { AdminDashboard } from 'views/pages/Admin/Dashboard/useGetRandomBibleQuotes';
import { ViewAllFamilies, ViewAllMinistries, ViewAllZones } from 'views/pages/Admin/Config';
import { Directory, DirectoryDetail } from 'views/pages/Admin/Directory';
import { permissionCodes } from 'variables/permissions';

const adminRoutes = [
  {
    path: '/dashboard',
    name: 'Overview',
    icon: 'ni ni-shop',
    component: AdminDashboard,
    layout: Layout.Admin,
  },
  {
    path: '/directory',
    name: 'Directory',
    icon: 'fa fa-users',
    component: Directory,
    layout: Layout.Admin,
  },
  {
    collapse: true,
    name: 'Users',
    icon: 'ni ni-circle-08',
    state: 'usersCollapse',
    permission: permissionCodes.CAN_ADD_MEMBERS,
    views: [
      {
        path: '/users/all',
        name: 'All Users',
        miniName: '.',
        component: ViewAllUsers,
        layout: Layout.Admin,
        permission: permissionCodes.CAN_ADD_MEMBERS,
      },
      {
        path: '/users/create-member',
        name: 'Add Member',
        miniName: '.',
        component: AddMember,
        layout: Layout.Admin,
        permission: permissionCodes.CAN_ADD_MEMBERS,
      },
      {
        path: '/users/roles',
        name: 'Roles',
        miniName: '.',
        component: ViewAllRoles,
        layout: Layout.Admin,
        permission: permissionCodes.CAN_SET_USER_ROLE,
      },
      {
        path: '/users/create-role',
        name: 'Create Role',
        miniName: '.',
        component: CreateRole,
        layout: Layout.Admin,
        permission: permissionCodes.CAN_SET_USER_ROLE,
      },
    ],
  },

  {
    collapse: true,
    name: 'Configurations',
    icon: 'ni ni-bag-17',
    state: 'productsCollapse',
    permission: permissionCodes.CAN_VIEW_CONFIGURATIONS,
    views: [
      {
        path: '/config/families',
        name: 'All Families',
        miniName: '.',
        component: ViewAllFamilies,
        layout: Layout.Admin,
        permission: permissionCodes.CAN_VIEW_CONFIGURATIONS,
      },
      {
        path: '/config/ministries',
        name: 'All Ministries',
        miniName: '.',
        component: ViewAllMinistries,
        layout: Layout.Admin,
        permission: permissionCodes.CAN_VIEW_CONFIGURATIONS,
      },
      {
        path: '/config/zones',
        name: 'All Zones',
        miniName: '.',
        component: ViewAllZones,
        layout: Layout.Admin,
        permission: permissionCodes.CAN_VIEW_CONFIGURATIONS,
      },
    ],
  },
  {
    path: '/audit/all',
    name: 'Audit Logs',
    icon: 'fa fa-history',
    component: ViewAllAuditLogs,
    layout: Layout.Admin,
    permission: permissionCodes.CAN_VIEW_AUDIT_LOGS,
  },
  {
    path: '/profile',
    component: UserProfileView,
    layout: Layout.Admin,
  },
  {
    path: '/profile/edit',
    component: UserProfileEdit,
    layout: Layout.Admin,
  },
  {
    path: '/users/edit/:userId',
    component: EditMember,
    layout: Layout.Admin,
  },
  {
    path: '/users/view/:userId',
    component: ViewUserDetail,
    layout: Layout.Admin,
  },
  {
    path: '/roles/:roleId',
    component: UpdateRole,
    layout: Layout.Admin,
  },
  {
    path: '/settings',
    component: Settings,
    layout: Layout.Admin,
  },
  {
    path: '/directory/detail/:userId',
    component: DirectoryDetail,
    layout: Layout.Admin,
  },
];

export default adminRoutes;
