import { apiUtils } from 'utils';
import { api } from 'variables';

const auditService = {
  getAllAuditLogs: async (params: string) => {
    return await apiUtils.getRequest(`${api.GET_AUDIT_LOGS}?${params}`);
  },
};

export default auditService;
