import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'quill/dist/quill.core.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/scss/app-styles.scss?v1.2.0';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { QueryClient, QueryClientProvider } from 'react-query';

import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import { Layout } from 'types/index';
import { pageRoutes } from 'variables/pageRoutes';
import { history } from 'utils/routerUtils';
import { ToastProvider } from 'components/Alerts/SweetToastAlert/ToastProvider';
import ToastAlertProvider from 'components/Alerts/ToastAlert/ToastAlertProvider';

const container = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cacheTime: 0,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <ToastProvider>
      <ToastAlertProvider>
        <Router history={history}>
          <Switch>
            <Route path={Layout.Admin} render={(props) => <AdminLayout {...props} />} />
            <Route path={Layout.Auth} render={(props) => <AuthLayout {...props} />} />
            <Redirect from="*" to={pageRoutes.LOGIN} />
          </Switch>
        </Router>
      </ToastAlertProvider>
    </ToastProvider>
  </QueryClientProvider>,
);
