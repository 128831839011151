import classnames from 'classnames';
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  NavLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { THEME_IMAGES } from 'assets/img/theme';
import { adminNavbarShortcuts, pageRoutes } from 'variables';
import { authUtils } from 'utils';
import { useGetLoggedInUserInfo } from 'views/pages/Admin/UserProfile/UserProfileView/useGetLoggedInUserInfo';

enum Theme {
  Dark = 'dark',
  Light = 'light',
}

interface AdminNavbarProps {
  theme: Theme;
  toggleSidenav: () => void;
  sidenavOpen: boolean;
}

const AdminNavbar: React.FC<AdminNavbarProps> = ({ theme, sidenavOpen, toggleSidenav }) => {
  const userInfo = authUtils.getUserInfo();
  const { user } = useGetLoggedInUserInfo();

  const addDefaultSrc = (ev: React.ChangeEvent<HTMLImageElement>) => {
    ev.target.src = THEME_IMAGES.defaultAvatar;
  };

  return (
    <>
      <Navbar
        className={classnames(
          'navbar-top navbar-expand border-bottom',
          { 'navbar-dark bg-dark-moss-green': theme === 'dark' },
          { 'navbar-light bg-secondary': theme === 'light' },
        )}
      >
        <NavLink className="d-xl-none">
          <div
            className={classnames(
              'pr-3 sidenav-toggler',
              { active: sidenavOpen },
              { 'sidenav-toggler-dark': theme === 'dark' },
            )}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </NavLink>
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto" navbar>
              {/* <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link cursor-hover" color="" tag="a">
                  <i className="ni ni-bell-55" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden" right>
                  <div className="px-3 py-3">
                    <h6 className="text-sm text-muted m-0">
                      You have <strong className="text-info">13</strong> notifications.
                    </h6>
                  </div>
                  <ListGroup flush>
                    <ListGroupItem
                      className="list-group-item-action"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      tag="a"
                    >
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img alt="..." className="avatar rounded-circle" src={THEME_IMAGES.team1} />
                        </Col>
                        <div className="col ml--2">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h4 className="mb-0 text-sm">John Snow</h4>
                            </div>
                            <div className="text-right text-muted">
                              <small>2 hrs ago</small>
                            </div>
                          </div>
                          <p className="text-sm mb-0">Let us meet at Starbucks at 11:30. Wdyt?</p>
                        </div>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                  <DropdownItem
                    className="text-center text-info font-weight-bold py-3"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    View all
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link cursor-hover" color="" tag="a">
                  <i className="fa fa-list-alt" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-dark bg-android-green" right>
                  <Row className="shortcuts px-4 justify-content-center">
                    {adminNavbarShortcuts.map((i, _idx) => (
                      <Col className="shortcut-item" xs="4" tag="a" key={_idx}>
                        <Link to={i.link}>
                          <span className={`shortcut-media avatar rounded-circle bg-gradient-${i.btnColor}`}>
                            <i className={i.icon} />
                          </span>
                          <small>{i.name}</small>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center cursor-hover">
                    <span>
                      <img
                        className="avatar avatar-sm rounded-circle"
                        alt="profile image"
                        src={(user?.profileImage as string) || THEME_IMAGES.defaultAvatar}
                        onError={addDefaultSrc}
                        style={{ objectFit: 'cover' }}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold text-capitalize">
                        {userInfo.firstName} {userInfo.lastName}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <Link to={pageRoutes.PROFILE}>
                    <DropdownItem>
                      <i className="ni ni-single-02" />
                      <span>My profile</span>
                    </DropdownItem>
                  </Link>
                  <Link to={pageRoutes.SETTINGS}>
                    <DropdownItem href={pageRoutes.SETTINGS}>
                      <i className="ni ni-settings-gear-65" />
                      <span>Settings</span>
                    </DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => authUtils.logoutUser()}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
