import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { GeneralErrorResponse } from 'types/auth';
import { userService } from 'services';
import { apiUtils, routerUtils } from 'utils';
import { useToastAlert } from 'components/Alerts';
import { pageRoutes } from 'variables';

export const useAddMember = () => {
  const { triggerSuccessAlert, triggerErrorAlert } = useToastAlert();

  const addMemberMutate = useMutation(userService.addMember, {
    onSuccess: () => {
      triggerSuccessAlert({ message: 'User created successfully!' });
      routerUtils.push(pageRoutes.ALL_USERS);
    },
    onError: (error: AxiosError<GeneralErrorResponse>) => {
      triggerErrorAlert({
        message: apiUtils.getAPIErrorMessage(error.response?.data?.message),
      });
    },
  });

  const handleSubmit = (payload: FormData) => {
    // @ts-expect-error
    addMemberMutate.mutate(payload);
  };

  return {
    handleSubmit,
    loading: addMemberMutate.isLoading,
    success: addMemberMutate.isSuccess,
  };
};
