/* eslint-disable @typescript-eslint/no-misused-promises */
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import DateDisplay from '../UserProfile/shared/DateDisplay';
import { useGetConfigs } from '../Config/hooks/useGetConfigs';
import { CreateConfigTypes } from '../Config/hooks/useCreateConfig';
import ChooseFileDisplay from '../UserProfile/shared/ChooseFileDisplay';
import { useGetUserInfo } from '../Users/hooks/useGetUserInfo';

import { useEditMember } from './useEditMember';

import AdminHeader from 'components/Headers/AdminHeader';
import { dropdownOptions, pageRoutes } from 'variables';
import { FormInput, FormSelectInput } from 'components/Forms';
import { UserData } from 'types/users';

const EditMember = ({
  hidePageHeader,
  id,
  isLoggedInUser,
}: {
  hidePageHeader: boolean;
  id?: string;
  isLoggedInUser?: boolean;
}) => {
  const { userId } = useParams();
  const { user: userDetail, loading: loadingUserDetail } = useGetUserInfo({ userId: id || userId });
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<UserData>({
    defaultValues: userDetail,
  });

  const { handleSubmit, loading } = useEditMember(id || userId, isLoggedInUser);
  const { dataDropdownOptionsNameValue } = useGetConfigs(CreateConfigTypes.MINISTRY);
  const [profileImage, setProfileImage] = useState<File | string | undefined>(undefined);

  const onSubmit = (data: UserData) => {
    const { email, firstName, lastName, membershipInfo } = data;

    const formData = new FormData();
    formData.append('membershipInfo', JSON.stringify(membershipInfo));
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    if (profileImage) {
      formData.append('profileImage', profileImage);
    }
    handleSubmit(formData);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setProfileImage(file);
    }
  };

  useEffect(() => {
    reset(userDetail);
  }, [userDetail]);

  return (
    <>
      {!hidePageHeader && (
        <AdminHeader
          pageTitle="Edit Profile"
          previousPageTitle={`${userDetail?.firstName} ${userDetail?.lastName}`}
          previousPageLink={`${pageRoutes.VIEW_USER_DETAIL}${userId}`}
        />
      )}
      <Container fluid className={isLoggedInUser ? '' : 'mt--6'}>
        {loadingUserDetail && <Skeleton count={5} height={50} className="mt-3" />}
        {userDetail && (
          <Form onSubmit={handleFormSubmit(onSubmit)}>
            <Card>
              <CardBody className="d-flex justify-content-center flex-column">
                <Row className="align-items-center">
                  <ChooseFileDisplay name="profileImage" label="Profile Image" handleFileChange={handleFileChange} />
                  <Col className="text-center">
                    {userDetail?.profileImage && !profileImage && (
                      <img
                        src={String(userDetail?.profileImage)}
                        alt="profile image"
                        className="img-fluid shadow"
                        style={{ maxWidth: '200px' }}
                      />
                    )}
                    {profileImage && (
                      <img
                        src={URL.createObjectURL(profileImage as Blob)}
                        alt="profile image"
                        className="img-fluid shadow"
                        style={{ maxWidth: '200px' }}
                      />
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody className="d-flex justify-content-center flex-column">
                <Row>
                  <FormInput
                    label="First Name"
                    id="firstName"
                    type="text"
                    name="firstName"
                    register={register}
                    errors={errors}
                    errorMessage="First name is required"
                  />
                  <FormInput
                    label="Last Name"
                    id="lastName"
                    type="text"
                    name="lastName"
                    register={register}
                    errors={errors}
                    errorMessage="Last name is required"
                  />
                  <FormInput
                    label="Other names"
                    id="otherNames"
                    type="text"
                    name="membershipInfo.otherNames"
                    register={register}
                  />
                  <FormInput
                    label="Email"
                    id="email"
                    type="email"
                    name="email"
                    register={register}
                    errors={errors}
                    errorMessage="Email is required"
                    disabled={Boolean(userDetail?.email)}
                  />
                  <FormInput
                    label="Phone Number"
                    id="phoneNumber"
                    type="number"
                    name="membershipInfo.phoneNumber"
                    placeholder="e.g 075123456789"
                    register={register}
                  />
                  <DateDisplay label="Date Of Birth" name="membershipInfo.dateOfBirth" register={register} />
                </Row>
              </CardBody>
            </Card>
            <hr />
            <Card>
              <CardBody className="d-flex justify-content-center flex-column">
                <Row>
                  <FormInput
                    label="Address"
                    id="address"
                    type="text"
                    name="membershipInfo.address"
                    register={register}
                  />
                  <FormInput label="City" id="city" type="text" name="membershipInfo.city" register={register} />
                  <FormInput
                    label="Post Code"
                    id="postCode"
                    type="text"
                    name="membershipInfo.postCode"
                    register={register}
                  />
                </Row>
              </CardBody>
            </Card>
            <hr />
            <Card>
              <CardBody className="d-flex justify-content-center flex-column">
                <Row>
                  <FormSelectInput
                    options={dropdownOptions.GENDER}
                    label="Gender"
                    control={control}
                    name="membershipInfo.gender"
                  />
                  <FormSelectInput
                    options={dropdownOptions.MARITAL_STATUS}
                    label="Marital Status"
                    control={control}
                    name="membershipInfo.maritalStatus"
                  />
                  <FormInput
                    label="Profession"
                    id="profession"
                    type="text"
                    name="membershipInfo.profession"
                    register={register}
                  />
                  <FormInput
                    label="Do you have any special need?"
                    id="specialNeed"
                    type="text"
                    name="membershipInfo.specialNeed"
                    register={register}
                  />
                </Row>
              </CardBody>
            </Card>
            <hr />
            <Card>
              <CardBody className="d-flex justify-content-center flex-column">
                <Row>
                  <FormInput
                    label="Next of Kin Full Name"
                    id="nextOfKinFullName"
                    type="text"
                    name="membershipInfo.nextOfKinFullName"
                    register={register}
                  />
                  <FormInput
                    label="Next of Kin Relationship"
                    id="nextOfKinRelationship"
                    type="text"
                    name="membershipInfo.nextOfKinRelationship"
                    register={register}
                  />
                  <FormInput
                    label="Next of Kin Address"
                    id="nextOfKinAddress"
                    type="text"
                    name="membershipInfo.nextOfKinAddress"
                    register={register}
                  />
                  <FormInput
                    label="Next of Kin Contact Number"
                    id="nextOfKinContact"
                    type="number"
                    name="membershipInfo.nextOfKinContact"
                    register={register}
                  />
                </Row>
              </CardBody>
            </Card>
            <hr />
            <Card>
              <CardBody className="d-flex justify-content-center flex-column">
                <Row className="mb-4">
                  <DateDisplay
                    label="Date Of Baptism"
                    name="membershipInfo.dateOfBaptism"
                    id="membershipInfo.dateOfBaptism"
                    register={register}
                  />
                  <FormInput
                    label="Place of Baptism"
                    id="placeOfBaptism"
                    type="text"
                    name="membershipInfo.placeOfBaptism"
                    register={register}
                  />
                  <FormSelectInput
                    options={dataDropdownOptionsNameValue}
                    label="Ministries of Interest"
                    control={control}
                    name="membershipInfo.ministriesOfInterests"
                    isMulti
                    // @ts-expect-error
                    defaultValue={userDetail?.membershipInfo?.ministriesOfInterests?.map((i) => ({
                      label: i,
                      value: i,
                    }))}
                  />
                </Row>
              </CardBody>
            </Card>
            <Row className="justify-content-center my-5">
              <Button color="primary" type="submit" size="md" style={{ width: '200px' }} disabled={loading}>
                {loading ? 'Saving ...' : 'Save'}
              </Button>
            </Row>
          </Form>
        )}
      </Container>
    </>
  );
};

export default EditMember;
