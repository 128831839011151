import classnames from 'classnames';
import { useState } from 'react';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import { LoginPayload } from 'types/auth';

interface LoginFormProps {
  loading: boolean;
  handleSubmit: (payload: LoginPayload) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ loading, handleSubmit }) => {
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [inputData, setFormInputData] = useState<LoginPayload>({ identifier: '', password: '' });

  const handleTextChange = (key: string, value: string) => {
    setFormInputData((prev) => ({ ...prev, [key]: value }));
  };

  const disableSubmit = loading || !inputData.identifier || !inputData.password;

  return (
    <Form role="form">
      <FormGroup
        className={classnames('mb-3', {
          focused: focusedEmail,
        })}
      >
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Email or Phone No"
            type="email"
            onFocus={() => setfocusedEmail(true)}
            onBlur={() => setfocusedEmail(true)}
            onChange={(e) => handleTextChange('identifier', e.target.value)}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup
        className={classnames({
          focused: focusedPassword,
        })}
      >
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            onFocus={() => setfocusedPassword(true)}
            onBlur={() => setfocusedPassword(true)}
            onChange={(e) => handleTextChange('password', e.target.value)}
          />
          <InputGroupAddon addonType="append" className="cursor-hover" onClick={() => setShowPassword(!showPassword)}>
            <InputGroupText>
              <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
      <div className="custom-control custom-control-alternative custom-checkbox">
        <input className="custom-control-input" id=" customCheckLogin" type="checkbox" />
        <label className="custom-control-label" htmlFor=" customCheckLogin">
          <span className="text-muted">Remember me</span>
        </label>
      </div>
      <div className="text-center">
        <Button
          className="my-4"
          color="primary"
          type="button"
          disabled={disableSubmit}
          onClick={() => handleSubmit(inputData)}
        >
          {loading ? 'Signing in...' : 'Sign in'}
        </Button>
      </div>
    </Form>
  );
};

export default LoginForm;
