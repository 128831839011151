import pageRoutes from './pageRoutes';

const adminNavbarShortcuts = [
  {
    name: 'Overview',
    link: pageRoutes.DASHBOARD,
    icon: 'fa fa-home',
    btnColor: 'dark',
  },
  {
    name: 'Directory',
    link: pageRoutes.DIRECTORY,
    icon: 'fa fa-users',
    btnColor: 'dark',
  },
];

export default adminNavbarShortcuts;
