const api = {
  CREATE_STAFF: 'auth/register_staff/',
  REGISTER_CUSTOMER: 'auth/register_customer/',
  LOGIN: 'auth/login/',
  CONFIRM_PASSWORD: 'auth/confirm_password/',
  FORGET_PASSWORD: 'auth/forgot-password/',

  CHANGE_PASSWORD: 'auth/change-password',
  SET_NEW_PASSWORD: 'auth/complete-forgot-password',
  FETCH_ALL_USERS: 'users/',

  FETCH_ALL_CUSTOMERS: 'users/customers/',
  CONFIRM_STAFF_ACCOUNT: 'users/confirm_staff_account/',
  CHANGE_USER_ACCOUNT_STATUS: 'users/change_account_status/',

  FORGOT_PASSWORD_VERIFY_OTP: 'auth/verify-forgot-password',
  INVITE_MEMBER: 'users/invite-member/',
  UPDATE_ROLE: 'roles/edit/',
  FETCH_ROLE_BY_ID: 'roles/detail/',
  FETCH_ALL_ROLES: 'roles/all',
  CREATE_ROLE: 'roles/create',
  FETCH_LOGGED_IN_USER_INFO: 'users/my-details',
  SET_USER_ROLE: 'users/set-user-role/',
  FETCH_USER_INFO: 'users/details/',
  ADD_MEMBER: 'users/create-member',
  EDIT_MEMBER: 'users/edit-member/',
  GET_PERMISSIONS: 'lookup/permissions',
  COMPLETE_ACCOUNT_SETUP: 'users/complete-account-setup',
  CREATE_MINISTRY: 'config/create-ministry',
  GET_ALL_MINISTRIES: 'config/get-ministries',
  CREATE_FAMILY: 'config/create-family',
  GET_ALL_FAMILIES: 'config/get-families',
  CREATE_ZONE: 'config/create-zone',
  GET_ALL_ZONES: 'config/get-zones',
  EDIT_MINISTRY: 'config/edit-ministry',
  EDIT_FAMILY: 'config/edit-family',
  EDIT_ZONE: 'config/edit-zone',
  SET_USER_CONFIG: 'config/set-user-configs/',
  GET_OVERVIEW_DATA: 'overview',
  GET_AUDIT_LOGS: 'audit/get-logs',
  GET_AUDIT_LOG_BY_ENTITY_ID: 'audit/get-by-entity/',

  GET_RANDOM_API_QUOTES: 'https://beta.ourmanna.com/api/v1/get',
  GET_USER_IP_ADDRESS: 'https://api.ipify.org/?format=json',
};

export default api;
