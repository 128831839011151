import pickBy from 'lodash.pickby';

const helperUtils = {
  convertFileToBase64: (file: File, cb: (result: string | ArrayBuffer | null) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb((reader.result as string).split(',')[1]);
    };
  },
  checkKeyValues: (obj: Object, optionalKey: string) => {
    for (const key in obj) {
      if (key !== optionalKey && !obj[key]) {
        return false;
      }
    }
    return true;
  },
  formatNumberWithCommas: (x?: string | number) => x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  formatDate: (date: string) => {
    const d = new Date(date);
    const currDate = d.getDate();
    const currMonth = d.getMonth();
    const currYear = d.getFullYear();
    return `${currYear}-${currMonth}-${currDate}  ${d.getHours()}:${d.getMinutes()}`;
  },
  removeObjPropWithEmptyValues: (obj: any) => {
    return Object.entries(obj).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});
  },
  removeNumbersFromString: (string?: string) => {
    if (!string) return '';
    return string.replace(/\d+|^\s+|\s+$/g, '');
  },
  createFilterUrlString: (params: any) => {
    const validParams = pickBy(params, (value: any) => !!value);
    const url = Object.entries(validParams)
      .map((e) => e.join('='))
      .join('&');
    return url;
  },
};

export default helperUtils;
