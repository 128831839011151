import React, { useState } from 'react';
import { Card, CardHeader, CardBody, FormGroup, Input, Container, Col, Button, Row } from 'reactstrap';

import { useChangePassword } from './useChangePassword';

import { ChangePasswordPayload } from 'types/auth';
import { EmbeddedAlert } from 'components/Alerts';
import { AlertType } from 'components/Alerts/EmbeddedAlert';

const ChangePassword = () => {
  const [changePasswordError, setChangePasswordError] = useState<string>('');
  const { handleSubmit, error, loading, success } = useChangePassword();

  const defaultValue = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const [changePassword, setChangePassword] = useState<ChangePasswordPayload>(defaultValue);
  const disableSubmit = loading || !changePassword.currentPassword || !changePassword.newPassword;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setChangePassword({ ...changePassword, [name]: value });
  };

  const handleChangePasswordSubmit = (payload: ChangePasswordPayload) => {
    const pattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[a-zA-Z\d]{6,})(?=.*[^a-zA-Z\d]).*$/;
    const isMatch = pattern.test(payload.newPassword);
    if (payload.newPassword !== payload.confirmPassword) {
      setChangePasswordError('Passwords do not match!');
      return;
    }
    if (!isMatch) {
      setChangePasswordError(
        'Password should be a minimum of 6 digits, at least a Capital letter and a special character',
      );
      return;
    }
    setChangePasswordError('');
    handleSubmit(changePassword);
  };

  return (
    <Container className="mt-6">
      {(error || changePasswordError) && (
        <EmbeddedAlert text={error || changePasswordError} variant={AlertType.Danger} />
      )}
      {success && <EmbeddedAlert text={'success'} variant={AlertType.Success} />}
      <Card>
        <CardHeader>
          <h3 className="mb-0">Change Password</h3>
          <small className="text-success">
            Password should be a minimum of 6 digits, at least a Capital letter and a special character
          </small>
        </CardHeader>
        <CardBody className="d-flex justify-content-center flex-column">
          <Row className="justify-content-center">
            <Col md="6">
              <FormGroup>
                <label className="form-control-label">Current Password</label>
                <Input
                  placeholder="Current password"
                  value={changePassword.currentPassword}
                  onChange={handleChange}
                  type="password"
                  name="currentPassword"
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">New Password</label>
                <Input
                  value={changePassword.newPassword}
                  onChange={handleChange}
                  placeholder="New password"
                  type="password"
                  name="newPassword"
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">Confirm New Password</label>
                <Input
                  placeholder="confirm new password"
                  value={changePassword.confirmPassword}
                  onChange={handleChange}
                  type="password"
                  name="confirmPassword"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Button
              color="primary"
              type="button"
              size="md"
              disabled={disableSubmit}
              onClick={() => handleChangePasswordSubmit(changePassword)}
            >
              {loading ? 'submitting ...' : 'Submit'}
            </Button>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default ChangePassword;
