import { Redirect, Route, RouterProps } from 'react-router-dom';

import { authUtils } from 'utils';

interface AuthenticatedRouteProps extends RouterProps {
  component: React.ReactNode;
}

const AutheticatedRoute: React.FC<AuthenticatedRouteProps> = ({ component, location, ...rest }) => {
  return (
    <Route
      {...rest}
      // @ts-expect-error
      component={
        authUtils.checkUserAuth()
          ? component
          : () => (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
              />
            )
      }
    />
  );
};

export default AutheticatedRoute;
