import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { auditService } from 'services';
import { AllAuditLogs } from 'types/audits';
import { GeneralErrorResponse } from 'types/auth';
import { queryKeys } from 'variables';

export const useGetAllAuditLogs = (paramsString?: string, page?: number) => {
  const { data, error, isLoading, refetch } = useQuery<AxiosResponse<AllAuditLogs>, AxiosError<GeneralErrorResponse>>(
    [queryKeys.ALL_AUDIT_LOGS, paramsString],
    () => auditService.getAllAuditLogs(paramsString || ''),
  );

  return {
    allAuditLogs: data?.data.data,
    count: data?.data.totalCount,
    pages: data?.data.totalPages,
    loading: isLoading,
    refetch: async () => {
      await refetch();
    },
    error,
  };
};
