import { Col, FormGroup, Input } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';

interface EditInputDisplayProps {
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type: InputType;
  name: string;
  label: string;
}

const EditInputDisplay: React.FC<EditInputDisplayProps> = ({ value, handleChange, placeholder, type, name, label }) => {
  return (
    <Col md="6">
      <FormGroup>
        <label className="form-control-label text-capitalize">{label}</label>
        <Input value={value} onChange={handleChange} placeholder={placeholder} type={type} name={name} />
      </FormGroup>
    </Col>
  );
};

export default EditInputDisplay;
