import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { configService } from 'services';
import { apiUtils } from 'utils';
import { useToastAlert } from 'components/Alerts';
import { APIErrorResponse } from 'types/index';
import { queryKeys } from 'variables';
import { ConfigData } from 'types/config';

export enum CreateConfigTypes {
  ZONE = 'Zone',
  MINISTRY = 'Ministry',
  FAMILY = 'Family',
}

interface CreateConfigProps {
  onSuccess?: () => void;
  isEdit?: boolean;
  configId?: string;
  configType: CreateConfigTypes;
}

const createAPI = {
  [CreateConfigTypes.ZONE]: configService.createZone,
  [CreateConfigTypes.MINISTRY]: configService.createMinistry,
  [CreateConfigTypes.FAMILY]: configService.createFamily,
};

const editAPI = {
  [CreateConfigTypes.ZONE]: configService.editZone,
  [CreateConfigTypes.MINISTRY]: configService.editMinistry,
  [CreateConfigTypes.FAMILY]: configService.editFamily,
};

export const configQueryKeys = {
  [CreateConfigTypes.ZONE]: queryKeys.GET_ZONES,
  [CreateConfigTypes.MINISTRY]: queryKeys.GET_MINISTRIES,
  [CreateConfigTypes.FAMILY]: queryKeys.GET_FAMILIES,
};

export const useCreateConfig = ({ onSuccess, isEdit, configId, configType }: CreateConfigProps) => {
  const queryClient = useQueryClient();
  const { triggerSuccessAlert, triggerErrorAlert } = useToastAlert();

  const configMutate = useMutation(isEdit && configId ? editAPI[configType] : createAPI[configType], {
    onSuccess: () => {
      triggerSuccessAlert({ message: `${configType} ${isEdit ? 'updated' : 'created'} successfully!` });
      queryClient.invalidateQueries({ queryKey: configQueryKeys[configType] });
      onSuccess?.();
    },
    onError: (error: AxiosError<APIErrorResponse>) => {
      triggerErrorAlert({ message: apiUtils.getAPIErrorMessage(error.response?.data?.message) });
    },
  });

  const handleSubmit = (payload: ConfigData) => {
    configMutate.mutate(payload);
  };

  return {
    handleSubmit,
    loading: configMutate.isLoading,
    success: configMutate.isSuccess,
  };
};
