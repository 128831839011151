/* eslint-disable @typescript-eslint/no-misused-promises */
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Modal } from 'reactstrap';
import { useForm } from 'react-hook-form';

import { useViewAllRoles } from '../Roles/useViewAllRoles';

import { useSetUserRole } from './hooks/useSetUserRole';

import { ModalCloseButton } from 'components/Buttons';
import { FormSelectInput } from 'components/Forms';

interface CreateModalProps {
  open: boolean;
  setOpenModal: (e: boolean) => void;
  id: string;
  refetchUserDetails?: () => void;
  defaultRole?: string;
}

const SetRoleModal: React.FC<CreateModalProps> = ({ open, setOpenModal, id, refetchUserDetails, defaultRole }) => {
  const { allRoles } = useViewAllRoles('');

  const dropdownArray = allRoles?.map((i) => ({
    label: i.name,
    value: i.id,
  }));

  const { loading, handleSubmit } = useSetUserRole({
    onSuccess: () => {
      setOpenModal(false);
      refetchUserDetails?.();
    },
  });

  const onSubmit = (data: { roleId: string }) => {
    handleSubmit(data, id);
  };

  const {
    handleSubmit: handleFormSubmit,
    control,
    formState: { errors },
  } = useForm<{ roleId: string }>({
    defaultValues: { roleId: defaultRole },
  });

  return (
    <>
      <Modal className="modal-dialog-centered" size="sm" isOpen={open} toggle={() => setOpenModal(false)}>
        <div className="modal-body p-0">
          <Card className="border-0 mb-0">
            <CardHeader>
              <h3 className="text-center d-inline">Set Role</h3>
              <ModalCloseButton onClick={setOpenModal} />
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleFormSubmit(onSubmit)}>
                <FormGroup>
                  <FormSelectInput
                    control={control}
                    block
                    options={dropdownArray as []}
                    name="roleId"
                    errors={errors}
                    errorMessage="Please select a role"
                    defaultValue={dropdownArray?.find((i) => i.value === defaultRole)}
                  />
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    {loading ? 'Submit...' : 'Submit'}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default SetRoleModal;
