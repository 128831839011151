import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { userService } from 'services';
import { constants, queryKeys } from 'variables';

export const useGetUserIPAddress = () => {
  const userIPAddressQuery = useQuery<AxiosResponse<{ ip: string }>, AxiosError<any>>(
    [queryKeys.GET_USER_IP],
    userService.getUserIpAddress,
    {
      onSuccess: (res) => {
        const { data } = res;
        if (data?.ip) {
          localStorage.setItem(constants.IP_ADDRESS, data.ip);
        }
      },
    },
  );

  return {
    ipAddress: userIPAddressQuery.data?.data?.ip,
    loading: userIPAddressQuery.isLoading,
    error: userIPAddressQuery.error,
  };
};
