import { Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { ICON_IMAGES } from 'assets/img/icons';
import { pageRoutes } from 'variables';

interface EldersCardProp {
  data?: Array<{
    firstName: string;
    lastName: string;
    profileImage: string;
    id: string;
  }>;
  loading?: boolean;
}

const PreachersCard: React.FC<EldersCardProp> = ({ data, loading }) => {
  return (
    <Card>
      <CardHeader>
        <h5 className="h3 mb-0">Preachers</h5>
      </CardHeader>
      <CardHeader className="py-0"></CardHeader>
      <CardBody>
        {loading && <Skeleton count={3} height={40} className="mt-3" />}
        <ListGroup className="list my--3" flush>
          {data
            ? data.map((i) => (
                <ListGroupItem className="px-0" key={i.id}>
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <Link to={`${pageRoutes.DIRECTORY_DETAIL}${i.id}`}>
                        <img
                          style={{ objectFit: 'cover' }}
                          className="avatar rounded-circle"
                          alt="."
                          src={i.profileImage || ICON_IMAGES.defaultImage}
                        />
                      </Link>
                    </Col>
                    <div className="col ml--2">
                      <h5 className="mb-0">
                        <Link to={`${pageRoutes.DIRECTORY_DETAIL}${i.id}`}>
                          {i.firstName} {i.lastName}
                        </Link>
                      </h5>
                    </div>
                  </Row>
                </ListGroupItem>
              ))
            : null}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

export default PreachersCard;
