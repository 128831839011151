import React from 'react';
import ReactPaginate from 'react-paginate';

interface PaginationProps {
  onChange: (page: number) => void;
  totalCount: number;
  pageCount: number;
  page?: number;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const { onChange, pageCount, page = 0 } = props;

  const handlePageClick = (data: { selected: number }) => {
    window.scrollTo(0, 0);
    onChange(data.selected + 1);
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <ReactPaginate
        previousLabel={<i className="fas fa-angle-left" />}
        nextLabel={<i className="fas fa-angle-right" />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-center'}
        pageClassName={'page-item'}
        activeClassName={'active'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        forcePage={page - 1}
      />
    </div>
  );
};

export default Pagination;
