import { Container } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

import { EditMember } from '../../AddMember';
import { useGetLoggedInUserInfo } from '../UserProfileView/useGetLoggedInUserInfo';

import { authUtils } from 'utils';

const UserProfileEdit = () => {
  const userInfo = authUtils.getUserInfo();
  const { user, loading } = useGetLoggedInUserInfo();

  const isLoggedInUser = userInfo?.id === user?.id;

  return (
    <Container className="mt-6">
      {loading ? (
        <Skeleton count={5} height={50} className="mt-3" />
      ) : (
        <div className="mt-5">
          <EditMember isLoggedInUser={isLoggedInUser} hidePageHeader={true} id={user?.id} />
        </div>
      )}
    </Container>
  );
};

export default UserProfileEdit;
