import { useParams } from 'react-router-dom';
import React from 'react';
import { Container, Card, CardBody, Row, Form, Button } from 'reactstrap';

import EditInputDisplay from '../UserProfile/shared/EditInputDisplay';
import { usePermissions } from '../Permissions/usePermissions';

import { useGetRole } from './useGetRole';
import { useUpdateRole } from './useUpdateRole';

import { EmbeddedAlert } from 'components/Alerts';
import { AlertType } from 'components/Alerts/EmbeddedAlert';
import { UpdateRoleData } from 'types/user';
import AdminHeader from 'components/Headers/AdminHeader';

const defaultUpdateRole = {
  name: '',
  description: '',
  permissions: [],
};
const updateRole = () => {
  const [updateRole, setUpdateRole] = React.useState<UpdateRoleData>(defaultUpdateRole);
  const [updateRoleError, setUpdateRoleError] = React.useState<string>('');

  const { roleId } = useParams();
  const { permissions } = usePermissions();

  let roleIdToUse = '';

  if (typeof roleId === 'string' && roleId) {
    roleIdToUse = roleId;
  }

  const { role } = useGetRole({ roleId: roleIdToUse });
  const { handleSubmit, error, loading } = useUpdateRole();

  React.useEffect(() => {
    if (role) {
      setUpdateRole({
        ...defaultUpdateRole,
        description: role?.description,
        name: role?.name,
        permissions: role?.permissions.map((i) => i.id),
      });
    }
  }, [role]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id?: string) => {
    const { name, value } = e.target;
    if (name === 'permissions' && typeof id === 'string') {
      if (updateRole.permissions.includes(id)) {
        setUpdateRole({ ...updateRole, [name]: updateRole.permissions.filter((i) => i !== id) });
      } else {
        setUpdateRole({ ...updateRole, [name]: [...updateRole.permissions, id] });
      }
    } else {
      setUpdateRole({ ...updateRole, [name]: value });
    }
  };

  const handleUpdateRoleSubmit = (payload: UpdateRoleData, roleIdToUse: string) => {
    setUpdateRoleError('');
    if (!Object.values(payload).every(Boolean)) {
      setUpdateRoleError('All fields are required!');
      return;
    }
    handleSubmit(payload, roleIdToUse);
  };

  return (
    <>
      <AdminHeader pageTitle="Update Roles" previousPageTitle="All Roles" previousPageLink={'/admin/users/roles'} />
      <Container className="mt--6">
        {(error || updateRoleError) && <EmbeddedAlert text={error || updateRoleError} variant={AlertType.Danger} />}
        <Card>
          <CardBody className="d-flex justify-content-center flex-column">
            <Form>
              <Row>
                <EditInputDisplay
                  handleChange={handleChange}
                  label="name"
                  value={updateRole.name}
                  name="name"
                  type="text"
                />
                <EditInputDisplay
                  handleChange={handleChange}
                  label="description"
                  value={updateRole?.description}
                  name="description"
                  type="text"
                />
              </Row>
              <div className="mt-2 mb-5">
                <label>
                  <strong>Permissions</strong>
                </label>
                {permissions?.length &&
                  permissions.map((i) => (
                    <div className="custom-control custom-checkbox custom-checkbox-success mb-3 mt-3" key={i.id}>
                      <input
                        className="custom-control-input"
                        id={i.id}
                        type="checkbox"
                        name="permissions"
                        checked={updateRole.permissions.includes(i.id)}
                        onChange={(e) => handleChange(e, i.id)}
                      />
                      <label className="custom-control-label" htmlFor={i.id}>
                        {i.name}
                      </label>
                    </div>
                  ))}
              </div>

              <Button
                color="primary"
                type="button"
                size="md"
                style={{ width: '200px' }}
                disabled={loading}
                onClick={() => handleUpdateRoleSubmit(updateRole, roleIdToUse)}
              >
                {loading ? 'Updating ...' : 'Update'}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default updateRole;
