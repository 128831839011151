import React from 'react';
import { useLocation, NavLink as NavLinkRRD, Link } from 'react-router-dom';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Button } from 'reactstrap';

import { CollapsibleRoute, Route } from 'types/index';
import useGetUserPermissionConfig from 'components/ProtectedView/useGetUserPermissionConfig';

interface SidebarProps {
  // function used to make sidenav mini or normal
  toggleSidenav: () => void;
  // prop to know if the sidenav is mini or normal
  sidenavOpen: boolean;
  // links that will be displayed inside the component
  routes: Route[];
  // logo
  logo: {
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: string;
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: string;
    // the image src of the logo
    imgSrc: string;
    // the alt for the img
    imgAlt: string;
  };
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ toggleSidenav, sidenavOpen, routes, logo, rtlActive }) => {
  const [state, setState] = React.useState({});
  const location = useLocation();

  const { userPermissions } = useGetUserPermissionConfig();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName) ? 'active' : '';
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes: CollapsibleRoute[]) => {
    if (!routes?.length) return false;

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.includes(routes[i].path)) {
        return true;
      }
    }
    return false;
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes: Route[]) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse && prop.state) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  React.useEffect(() => {
    setState(getCollapseStates(routes));
    // eslint-disable-next-line
  }, []);

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes: Route[]) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (!prop.name || (prop.permission && !userPermissions[prop.permission])) {
        return null;
      }
      if (prop.collapse) {
        const st = {};
        st[prop.state] = !state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              href="#"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              className={classnames({
                active: getCollapseInitialState(prop.views),
              })}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={state[prop.state]}>
              <Nav className="nav-sm flex-column">{createLinks(prop.views)}</Nav>
            </Collapse>
          </NavItem>
        );
      }
      return prop.layout ? (
        <NavItem className={activeRoute(`${prop.layout}${prop.path}`)} key={key}>
          <NavLink
            to={`${prop.layout}${prop.path}`}
            activeClassName="text-primary font-weight-bold"
            onClick={closeSidenav}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span className="nav-link-text">{prop.name}</span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      ) : null;
    });
  };

  let navbarBrandProps;
  if (logo?.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo?.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    };
  }
  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
          </NavbarBrand>
        ) : null}
        <div className="ml-auto">
          <div
            className={classnames('sidenav-toggler d-none d-xl-block', {
              active: sidenavOpen,
            })}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </div>
        <Button color="none" className="d-xl-none" onClick={toggleSidenav}>
          <i className="fa fa-close text-gray" style={{ fontSize: '30px' }}></i>
        </Button>
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </div>
    </div>
  );
  return (
    <Navbar
      className={'sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ' + (rtlActive ? '' : 'fixed-left')}
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.includes('Win') ? <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar> : scrollBarInner}
    </Navbar>
  );
};

export default Sidebar;
