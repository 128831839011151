import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { userService } from 'services';
import { GetRoleResponse, GetRoleError } from 'types/user';
import { queryKeys } from 'variables';

export const useGetRole = ({ roleId }: { roleId: string }) => {
  const { data, error, isLoading, refetch } = useQuery<AxiosResponse<GetRoleResponse>, AxiosError<GetRoleError>>(
    [queryKeys.GET_ROLE, roleId],
    () => userService.getRole(roleId),
  );
  return {
    role: data?.data.data,
    loading: isLoading,
    refetch: async () => {
      await refetch();
    },
    error,
  };
};
