import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';

import { userService } from 'services';
import { SetStaffPassword } from 'types/user';
import { apiUtils, authUtils } from 'utils';
import { useToastAlert } from 'components/Alerts';

export const useCompleteAccountSetup = () => {
  const [error, setError] = useState<string>('');
  const { triggerSuccessAlert } = useToastAlert();

  const completeAccountSetupMutate = useMutation(userService.completeAccountSetup, {
    onSuccess: () => {
      authUtils.logoutUser();
      triggerSuccessAlert({ title: 'Account setup successful', message: 'Login to continue.' });
    },
    onError: (error: AxiosError<any>) => {
      const { response } = error;
      setError(apiUtils.getAPIErrorMessage(response?.data.message));
    },
  });

  const handleSubmit = (payload: SetStaffPassword) => {
    setError('');
    completeAccountSetupMutate.mutate(payload);
  };

  return {
    handleSubmit,
    loading: completeAccountSetupMutate.isLoading,
    error,
    success: completeAccountSetupMutate.isSuccess,
  };
};
