import React, { useState } from 'react';
import { Button, Modal } from 'reactstrap';

import { ICON_IMAGES } from 'assets/img/icons';

interface ViewImageModalProps {
  imageSrc?: string;
  viewImageText?: string;
  block?: boolean;
  children?: React.ReactNode;
}

const ViewProfileImageModal: React.FC<ViewImageModalProps> = ({ imageSrc, children, block }) => {
  const [showModal, setShowModal] = useState(false);

  const addDefaultSrc = (ev: React.ChangeEvent<HTMLImageElement>) => {
    ev.target.src = ICON_IMAGES.imageNotFound;
  };

  return (
    <>
      <Button
        block={block}
        color="none"
        onClick={() => setShowModal(true)}
        className="p-0"
        style={{ borderRadius: '50%' }}
      >
        {children}
      </Button>
      <Modal size="xl" className="modal-dialog-centered" isOpen={showModal} toggle={() => setShowModal(false)}>
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            <span aria-hidden={true}>
              <i className="fa fa-close"></i>
            </span>
          </button>
        </div>
        <div className="modal-body">
          <img src={imageSrc} onError={addDefaultSrc} className="mx-auto d-block" style={{ maxWidth: '100%' }} />
        </div>
      </Modal>
    </>
  );
};

export default ViewProfileImageModal;
