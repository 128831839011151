import img1x600 from 'assets/img/theme/img-1-1000x600.jpg';
import img1x900 from 'assets/img/theme/img-1-1000x900.jpg';
import angular from 'assets/img/theme/angular.jpg';
import bootstrap from 'assets/img/theme/bootstrap.jpg';
import landing1 from 'assets/img/theme/landing-1.png';
import landing2 from 'assets/img/theme/landing-2.png';
import landing3 from 'assets/img/theme/landing-3.png';
import profileCover from 'assets/img/theme/profile-cover.jpg';
import team1 from 'assets/img/theme/team-1.jpg';
import team2 from 'assets/img/theme/team-2.jpg';
import team3 from 'assets/img/theme/team-3.jpg';
import team4 from 'assets/img/theme/team-4.jpg';
import team5 from 'assets/img/theme/team-5.jpg';
import vue from 'assets/img/theme/vue.jpg';
import sketch from 'assets/img/theme/sketch.jpg';
import react from 'assets/img/theme/react.jpg';
import defaultAvatar from 'assets/img/theme/default-avatar.png';
import homeBG from 'assets/img/theme/coc-stratford-bg.jpg';

export const THEME_IMAGES = {
  img1x600,
  img1x900,
  angular,
  bootstrap,
  landing1,
  landing2,
  landing3,
  profileCover,
  team1,
  team2,
  team3,
  team4,
  team5,
  vue,
  sketch,
  react,
  defaultAvatar,
  homeBG,
};
