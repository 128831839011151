import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { userService } from 'services';
import { queryKeys } from 'variables';

export interface OverviewResponseData {
  success: true;
  data: {
    totalMembers: number;
    totalFamilies: number;
    totalMinistries: 2;
    totalZones: 1;
    elders: Array<{
      firstName: string;
      lastName: string;
      profileImage: string;
      id: string;
    }>;
    deacons: Array<{
      firstName: string;
      lastName: string;
      profileImage: string;
      id: string;
    }>;
    preachers: Array<{
      firstName: string;
      lastName: string;
      profileImage: string;
      id: string;
    }>;
  };
}

export const useGetOverviewData = () => {
  const { data, error, isLoading, refetch } = useQuery<AxiosResponse<OverviewResponseData>, AxiosError<any>>(
    [queryKeys.GET_OVERVIEW_DATA],
    () => userService.getOverviewData(),
  );

  return {
    overview: data?.data.data,
    loading: isLoading,
    refetch: async () => {
      await refetch();
    },
    error,
  };
};
