import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';

import { authService } from 'services';
import { ForgotPasswordErrorResponse, ForgotPasswordPayload, ForgotPasswordResponse } from 'types/auth';
import { apiUtils, routerUtils } from 'utils';
import { pageRoutes } from 'variables';

export const useForgotPassword = () => {
  const [error, setError] = useState<string>('');

  const forgotPasswordMutate = useMutation(authService.forgotPassword, {
    onSuccess: (res: AxiosResponse<ForgotPasswordResponse>, payload) => {
      routerUtils.push(`${pageRoutes.FORGOT_PASSWORD_VERIFICATION}?email=${payload.email}`);
    },
    onError: (error: AxiosError<ForgotPasswordErrorResponse>) => {
      const { response } = error;
      setError(apiUtils.getAPIErrorMessage(response?.data.message));
    },
  });

  const handleSubmit = (payload: ForgotPasswordPayload) => {
    setError('');
    forgotPasswordMutate.mutate(payload);
  };

  return {
    handleSubmit,
    loading: forgotPasswordMutate.isLoading,
    error,
  };
};
