import { ChangePassword } from './ChangePassword';

const Settings = () => {
  return (
    <>
      <ChangePassword />
    </>
  );
};

export default Settings;
