import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { userService } from 'services';
import { GetPermissions, GetPermissionsError } from 'types/users';
import { queryKeys } from 'variables';

export const usePermissions = (paramsString?: string) => {
  const { data, error, isLoading, refetch } = useQuery<AxiosResponse<GetPermissions>, AxiosError<GetPermissionsError>>(
    queryKeys.GET_PERMISSIONS,
    () => userService.getPermissions(),
  );

  return {
    permissions: data?.data.data,
    loading: isLoading,
    refetch: async () => {
      await refetch();
    },
    error,
  };
};
