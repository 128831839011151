import { createContext, useRef } from 'react';
import NotificationAlert from 'react-notification-alert';

enum ToastAlertType {
  DEFAULT = 'default',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

interface ToastTriggerProps {
  type?: ToastAlertType;
  title?: string;
  message?: string;
}

interface ToastAlertProviderProps {
  children: React.ReactNode;
}

const defaultTriggerValue = (params: ToastTriggerProps) => {};

export const ToastAlertContext = createContext({
  triggerBasicAlert: defaultTriggerValue,
  triggerSuccessAlert: defaultTriggerValue,
  triggerInfoAlert: defaultTriggerValue,
  triggerErrorAlert: defaultTriggerValue,
});

export const ToastAlertProvider: React.FC<ToastAlertProviderProps> = ({ children }) => {
  const notificationAlertRef = useRef<NotificationAlert>(null);

  const notify = ({ type, title, message }: ToastTriggerProps) => {
    const options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type,
      icon: 'ni ni-bell-55',
      autoDismiss: 10,
    };
    // @ts-expect-error
    notificationAlertRef.current?.notificationAlert(options);
  };

  const triggerBasicAlert = ({ title, message }: ToastTriggerProps) => {
    notify({ type: ToastAlertType.DEFAULT, title, message });
  };

  const triggerSuccessAlert = ({ title, message }: ToastTriggerProps) => {
    notify({ type: ToastAlertType.SUCCESS, title, message });
  };

  const triggerInfoAlert = ({ title, message }: ToastTriggerProps) => {
    notify({ type: ToastAlertType.INFO, title, message });
  };

  const triggerErrorAlert = ({ title, message }: ToastTriggerProps) => {
    notify({ type: ToastAlertType.DANGER, title, message });
  };

  return (
    <ToastAlertContext.Provider value={{ triggerBasicAlert, triggerSuccessAlert, triggerInfoAlert, triggerErrorAlert }}>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {children}
    </ToastAlertContext.Provider>
  );
};

export default ToastAlertProvider;
