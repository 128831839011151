import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';

import { CreateRoleData } from 'types/user';
import { userService } from 'services';
import { apiUtils, routerUtils } from 'utils';
import { GeneralErrorResponse } from 'types/auth';
import { useToastAlert } from 'components/Alerts';
import { pageRoutes } from 'variables';

export const useCreateRole = () => {
  const [error, setError] = useState<string>('');
  const { triggerSuccessAlert } = useToastAlert();
  const createRoleMutate = useMutation(userService.createRole, {
    onSuccess: () => {
      triggerSuccessAlert({ message: 'Role created successfully!' });
      routerUtils.push(pageRoutes.ALL_ROLES);
    },
    onError: (error: AxiosError<GeneralErrorResponse>) => {
      const { message } = { ...error.response?.data };
      setError(apiUtils.getAPIErrorMessage(message));
    },
  });

  const handleSubmit = (payload: CreateRoleData) => {
    setError('');
    createRoleMutate.mutate(payload);
  };

  return {
    handleSubmit,
    loading: createRoleMutate.isLoading,
    error,
    success: createRoleMutate.isSuccess,
  };
};
