import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';

interface ButtonWithTooltipProps {
  id: string;
  onClick: (e?: any) => void;
  tooltipText: string;
  children: React.ReactNode;
  size?: string;
  color?: string;
  iconClassName?: string;
}

const ButtonWithTooltip: React.FC<ButtonWithTooltipProps> = ({
  id,
  onClick,
  size,
  children,
  tooltipText,
  color,
  iconClassName,
}) => {
  return (
    <>
      <Button className="btn-round btn-icon" color={color ?? 'primary'} id={id} onClick={onClick} size={size ?? 'sm'}>
        <span className="btn-inner--icon mr-1">
          <i className={`fas ${iconClassName ?? 'fa-file-export'}`} />
        </span>
        <span className="btn-inner--text">{children}</span>
      </Button>
      <UncontrolledTooltip delay={0} target={id}>
        {tooltipText}
      </UncontrolledTooltip>
    </>
  );
};

export default ButtonWithTooltip;
