import Select, { MultiValue, SingleValue } from 'react-select';
import { Col, FormGroup } from 'reactstrap';
import { Control, useController } from 'react-hook-form';

export type SelectValue = MultiValue<string | number> | SingleValue<string | number>;

export interface SelectOption {
  value?: string | number | boolean;
  label: string;
}
export interface FormSelectInputProps {
  isMulti?: boolean;
  isLoading?: boolean;
  options: SelectOption[];
  block?: boolean;
  placeholder?: string;
  label?: string;
  value?: string | number | boolean | SelectOption;
  defaultValue?: string | number | boolean | SelectOption;
  defaultInputValue?: string;
  isDisabled?: boolean;
  onChange?: (e: SelectOption) => void;
  control?: Control<any>;
  name?: string;
  errorMessage?: string;
  errors?: any;
}

const FormSelectInputRegister: React.FC<FormSelectInputProps> = ({
  isMulti,
  isLoading,
  isDisabled,
  options,
  block,
  placeholder,
  label,
  defaultInputValue,
  defaultValue,
  control,
  name,
  errorMessage,
  errors,
}) => {
  const { field } = useController({
    name: name || '',
    control: control ?? undefined,
    rules: { required: errorMessage },
  });

  const handleSelect = (e: any) => {
    if (Array.isArray(e)) {
      const arrayOfValues = e.map((i) => i.value);
      field.onChange(arrayOfValues);
    } else {
      const { value } = e;
      field.onChange(value);
    }
  };
  return (
    <Col md={block ? '12' : '6'}>
      <FormGroup>
        <label className="form-control-label text-capitalize">{label}</label>
        <Select
          value={options?.find(({ value }) => value === field.value)}
          onChange={handleSelect}
          placeholder={placeholder ?? 'Select...'}
          isLoading={isLoading}
          isMulti={isMulti}
          options={options}
          defaultInputValue={defaultInputValue}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          styles={{
            input: (base) => ({
              ...base,
              height: 35,
              color: '#8898aa',
            }),
            option: (base) => ({
              ...base,
              backgroundColor: '#fff',
              color: '#000',
              ':hover': {
                backgroundColor: '#eee',
              },
            }),
          }}
        />
        {errors?.[name as string] && <small className="text-danger">{errors?.[name as string]?.message}</small>}
      </FormGroup>
    </Col>
  );
};

export default FormSelectInputRegister;
