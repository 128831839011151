const dropdownOptions = {
  VERIFICATION_STATUS: [
    { label: 'Pending', value: 'pending' },
    { label: 'Invited', value: 'invited' },
    { label: 'Activated', value: 'activated' },
    { label: 'Deactivated', value: 'deactivated' },
  ],
  GENDER: [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ],
  MARITAL_STATUS: [
    { label: 'Single', value: 'Single' },
    { label: 'Married', value: 'Married' },
    { label: 'Divorced', value: 'Divorced' },
    { label: 'Other', value: 'Other' },
  ],
  YES_AND_NO_STR: [
    { label: 'YES', value: 'true' },
    { label: 'NO', value: 'false' },
  ],
  YES_AND_NO: [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ],
  OFFICE_OCCUPIED: [
    { label: 'ELDER', value: 'ELDER' },
    { label: 'DEACON', value: 'DEACON' },
    { label: 'PREACHER', value: 'PREACHER' },
  ],
};

export default dropdownOptions;
