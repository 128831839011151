import FormSelectInput, { SelectOption } from 'components/Forms/FormSelectInput';
import { dropdownOptions } from 'variables';

interface YesNoProps {
  defaultValue?: string | number | boolean | SelectOption;
  label: string;
  onChange: (e: SelectOption) => void;
  isLoading?: boolean;
}
const Yes_No: React.FC<YesNoProps> = ({ defaultValue, label, isLoading, onChange }) => {
  return (
    <FormSelectInput
      defaultValue={defaultValue}
      label={label}
      onChange={onChange}
      options={dropdownOptions.YES_AND_NO_STR}
      isLoading={isLoading}
      block
    />
  );
};

export default Yes_No;
