import { useState } from 'react';

import { helperUtils } from 'utils';
import { constants } from 'variables';

export interface FilterParams {
  page?: number;
  pageSize?: number;
  brand?: string;
  in_stock?: string;
  categories?: string;
  search?: string;
  is_staff?: string;
  is_superuser?: string;
  created_by?: string;
}

export const useFilterParams = () => {
  const [params, setParams] = useState<FilterParams>({
    page: 1,
    pageSize: constants.PAGE_SIZE,
    brand: '',
    in_stock: '',
    categories: '',
    search: '',
    is_staff: '',
    is_superuser: '',
  });

  return {
    setParams: ({ ...data }: FilterParams) => setParams({ ...params, ...data }),
    params,
    paramsString: helperUtils.createFilterUrlString(params),
    pageSize: constants.PAGE_SIZE,
  };
};
