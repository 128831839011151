import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { configQueryKeys, CreateConfigTypes } from './useCreateConfig';

import { configService } from 'services';
import { GeneralErrorResponse } from 'types/auth';
import { ConfigAPIResponse } from 'types/config';
import { dropdownUtils } from 'utils';

const configAPIService = {
  [CreateConfigTypes.ZONE]: configService.getAllZones,
  [CreateConfigTypes.MINISTRY]: configService.getAllMinistries,
  [CreateConfigTypes.FAMILY]: configService.getAllFamilies,
};

export const useGetConfigs = (configType: CreateConfigTypes) => {
  const { data, error, isLoading, refetch } = useQuery<
    AxiosResponse<ConfigAPIResponse>,
    AxiosError<GeneralErrorResponse>
  >([configQueryKeys[configType]], () => configAPIService[configType]());

  return {
    data: data?.data.data,
    dataDropdownOptionsNameValue: dropdownUtils.configWithNameValue(data?.data.data),
    dataDropdownOptionsIdValue: dropdownUtils.configWithIdValue(data?.data.data),
    loading: isLoading,
    refetch: async () => {
      await refetch();
    },
    error,
  };
};
