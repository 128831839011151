import { Container, Row, Col } from 'reactstrap';

import { useGetUserIPAddress } from 'hooks/useGetUserIPAddress';
import { constants } from 'variables';

interface AuthHeaderProps {
  title: string;
  lead: string;
}

const AuthHeader: React.FC<AuthHeaderProps> = ({ title, lead }) => {
  const userIp = localStorage.getItem(constants.IP_ADDRESS);
  !userIp && useGetUserIPAddress();
  return (
    <>
      <div className="header bg-gradient-lighter py-7 py-lg-8">
        <Container>
          <div className="header-body text-center mb-4">
            <Row className="justify-content-center">
              <Col className="px-5" lg="6" md="8" xl="5">
                {title ? <h1>{title}</h1> : null}
                {lead ? <p className="text-lead">{lead}</p> : null}
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default bg-danger" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
    </>
  );
};

export default AuthHeader;
