import { Card, CardBody } from 'reactstrap';

import { useGetRandomBibleQuotes } from 'hooks/useGetRandomBibleQuotes';
import { Loader } from 'components/Loader';

const BibleQuoteCard = () => {
  const { bibleQuote, loading } = useGetRandomBibleQuotes();
  return (
    <Card className="bg-palm-leaf">
      <CardBody>
        <Loader loading={loading} size={40} />
        {typeof bibleQuote === 'string' && (
          <div className="my-4">
            <span className="h6 surtitle text-white">Quote of the day</span>
            <p className="text-white pt-2">{bibleQuote}</p>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default BibleQuoteCard;
