import { Layout } from 'types/index';

const createAdminPath = (route: string) => {
  return `${Layout.Admin}/${route}`;
};

export const pageRoutes = {
  LOGIN: '/',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_VERIFICATION: '/verify-forgot-password',
  SET_NEW_PASSWORD: '/set-new-password',
  SET_NEW_STAFF_PASSWORD: '/set-new-staff-password',
  SETUP_ACCOUNT: '/setup-account',
  COMPLETE_ACCOUNT_SETUP: '/complete-account-setup',
  DASHBOARD: createAdminPath('dashboard'),
  SETTINGS: createAdminPath('settings'),
  ALL_USERS: createAdminPath('users/all'),
  PROFILE: createAdminPath('profile'),
  ALL_PRODUCTS: createAdminPath('products/all'),
  CREATE_PRODUCTS: createAdminPath('products/create'),
  CREATE_ROLE: createAdminPath('users/create-role'),
  ALL_ROLES: createAdminPath('users/roles'),

  DIRECTORY_DETAIL: createAdminPath('directory/detail/'),
  DIRECTORY: createAdminPath('directory'),
  EDIT_ROLE: createAdminPath('roles/'),
  VIEW_PRODUCT_DETAIL: createAdminPath('product/detail/'),
  EDIT_PRODUCT: createAdminPath('product/edit/'),
  PRODUCT_DETAIL: createAdminPath('product/detail/'),
  ALL_PURCHASES: createAdminPath('purchases/all'),
  CREATE_PURCHASE: createAdminPath('purchases/create'),
  EDIT_PURCHASE: createAdminPath('purchases/edit/'),
  VIEW_PURCHASE_DETAIL: createAdminPath('purchases/detail/'),
  ALL_CUSTOMERS: createAdminPath('customers/all'),
  VIEW_CUSTOMER_DETAIL: createAdminPath('customers/'),
  CREATE_CUSTOMER: createAdminPath('customers/create'),
  VIEW_PAYMENT_DETAIL: createAdminPath('payments/'),
  ALL_PAYMENTS: createAdminPath('payments/all'),

  PROFILE_EDIT: createAdminPath('profile/edit'),
  VIEW_USER_DETAIL: createAdminPath('users/view/'),
  CREATE_MEMBER: createAdminPath('users/create-member'),
  EDIT_MEMBER: createAdminPath('users/edit/'),
};

export default pageRoutes;
