import React from 'react';
import { Col, FormGroup } from 'reactstrap';

interface ChooseFileDisplayData {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label?: string;
  block?: boolean;
}

const ChooseFileDisplay = ({ handleFileChange, name, label, block }: ChooseFileDisplayData) => {
  return (
    <Col md={block ? '12' : '6'}>
      <FormGroup>
        <label className="form-control-label text-capitalize">{label}</label>
        <div className="custom-file mb-3 col">
          <input
            className="custom-file-input"
            id="customFileLang"
            lang="en"
            type="file"
            onChange={handleFileChange}
            name={name}
            accept=".jpg,.jpeg,.png"
          />
          <label className="custom-file-label" htmlFor="customFileLang" />
        </div>
      </FormGroup>
    </Col>
  );
};

export default ChooseFileDisplay;
