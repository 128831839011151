import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import isEmpty from 'lodash.isempty';
import some from 'lodash.some';
import classNames from 'classnames';

import { Roles, Search, Users } from 'components/Filters';
import { FilterParams } from 'hooks/useFilterParams';
import Yes_No from 'components/Filters/Yes_No';

export enum FilterEnum {
  USERS = 'Users',
  ROLES = 'Roles',
  SEARCH_INPUT = 'Search',
  IS_SUPERUSER = 'is_Superuser',
  VERIFICATION_STATUS = 'Verification_status',
  CREATED_BY = 'Creeated_By',
}

const defaultFilters: FilterParams = {
  search: '',
  page: 1,
  brand: '',
  in_stock: '',
  categories: '',
  is_staff: '',
  is_superuser: '',
  created_by: '',
};

interface PageFiltersProps {
  filters: FilterEnum[];
  setParams?: (params: FilterParams) => void;
  setShowPageFilter?: (e?: boolean) => void;
}

export const PageFilters: React.FC<PageFiltersProps> = ({
  filters,
  setParams = () => {},
  setShowPageFilter = () => {},
}) => {
  const [filterState, setFilterState] = useState(defaultFilters);

  const handleSubmit = () => {
    setParams({ ...filterState, page: 1 });
  };

  return (
    <>
      <Container fluid className="mt-3">
        <Row className="justify-center">
          <Card className="w-100 bg-translucent-neutral">
            <CardBody>
              <Row
                className={classNames({
                  'justify-content-center': filters.length <= 2,
                })}
              >
                {filters.includes(FilterEnum.SEARCH_INPUT) && (
                  <Col sm="12" md="4">
                    <Search onChange={(e) => setFilterState({ search: e.target.value })} />
                  </Col>
                )}
                {filters.includes(FilterEnum.USERS) && (
                  <Col sm="12" md="4">
                    <Users onChange={(e) => setFilterState({ search: e.label })} />
                  </Col>
                )}
                {filters.includes(FilterEnum.ROLES) && (
                  <Col sm="12" md="4">
                    <Roles onChange={() => {}} />
                  </Col>
                )}
                {filters.includes(FilterEnum.IS_SUPERUSER) && (
                  <Col sm="12" md="4">
                    <Yes_No
                      label="Is Superuser"
                      onChange={(e) => setFilterState({ is_superuser: e.value as string })}
                    />
                  </Col>
                )}
                {filters.includes(FilterEnum.CREATED_BY) && (
                  <Col sm="12" md="4">
                    <Users label="Created By" onChange={(e) => setFilterState({ created_by: e.value as string })} />
                  </Col>
                )}
              </Row>
              <Row>
                <Col className="text-center">
                  <Button
                    className="btn-sm"
                    color="success"
                    onClick={() => handleSubmit()}
                    disabled={some(filterState, isEmpty)}
                  >
                    Search <i className="fa fa-search" />
                  </Button>
                  <Button
                    className="btn-sm"
                    color="warning"
                    onClick={() => {
                      setParams({ ...defaultFilters });
                      setShowPageFilter();
                    }}
                  >
                    Clear search <i className="fa fa-cancel" />
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </>
  );
};

export default PageFilters;
