import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Button,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import convertToCamelCase from 'lodash.camelcase';

import { useViewAllRoles } from './useViewAllRoles';

import AdminHeader from 'components/Headers/AdminHeader';
import { DataTableError } from 'components/DataTableError';
import { ButtonWithTooltip } from 'components/Buttons';
import { routerUtils } from 'utils';
import { pageRoutes } from 'variables';
import { useFilterParams } from 'hooks/useFilterParams';

const ViewAllRoles = () => {
  const { paramsString } = useFilterParams();
  const { allRoles, error, loading, refetch } = useViewAllRoles(paramsString);
  return (
    <>
      <AdminHeader pageTitle="All Roles" />
      <Container className="mt--6">
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col className="text-right offset-6" xs="6">
                <ButtonWithTooltip
                  id="createRoles"
                  onClick={() => routerUtils.push(pageRoutes.CREATE_ROLE)}
                  tooltipText="Create new role"
                  iconClassName="fa fa-add"
                >
                  Create Role
                </ButtonWithTooltip>
              </Col>
            </Row>
            {loading && <Skeleton count={5} height={50} className="mt-3" />}
            <DataTableError
              isEmptyError={!allRoles?.length}
              error={error?.message}
              isRefreshing={loading}
              onRefresh={refetch}
            />
          </CardHeader>
          {!!allRoles?.length && (
            <Table className="align-items-center table-flush" responsive striped>
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Permissions</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {allRoles.map((i, _idx) => (
                  <tr key={i.id}>
                    <td>
                      <strong>{i.name}</strong>
                    </td>
                    <td>{i.description}</td>
                    <td>
                      {i?.permissions.length &&
                        i?.permissions.map((permission, idx) => (
                          <p key={idx}>
                            <small>{permission.name}</small>
                          </p>
                        ))}
                    </td>
                    <td>
                      <Button color="link" id={convertToCamelCase(i.name)}>
                        <i className="fas fa-ellipsis-v" />
                      </Button>
                      <UncontrolledPopover placement="left" target={convertToCamelCase(i.name)} trigger="legacy">
                        <PopoverBody>
                          <ListGroup flush>
                            <ListGroupItem>
                              <Link to={`${pageRoutes.EDIT_ROLE}${i.id}`}>
                                <i className="fas fa-eye text-success pr-3" />
                                Edit Role
                              </Link>
                            </ListGroupItem>
                          </ListGroup>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card>
      </Container>
    </>
  );
};

export default ViewAllRoles;
