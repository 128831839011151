import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';

import { authService } from 'services';
import { ForgotPasswordErrorResponse, VerifyForgotPasswordPayload, VerifyForgotPasswordResponse } from 'types/auth';
import { apiUtils, routerUtils } from 'utils';
import { constants, pageRoutes } from 'variables';

export const useVerifyForgotPassword = () => {
  const [error, setError] = useState<string>('');

  const verifyForgotPasswordMutate = useMutation(authService.verifyForgotPassword, {
    onSuccess: (res: AxiosResponse<VerifyForgotPasswordResponse>, payload) => {
      const { data } = res;
      routerUtils.push(`${pageRoutes.SET_NEW_PASSWORD}?email=${payload.email}`);
      localStorage.setItem(constants.RESET_PASSWORD_AUTH_TOKEN, data.data.token);
    },
    onError: (error: AxiosError<ForgotPasswordErrorResponse>) => {
      const { response } = error;
      setError(apiUtils.getAPIErrorMessage(response?.data.message));
    },
  });

  const handleSubmit = (payload: VerifyForgotPasswordPayload) => {
    setError('');
    verifyForgotPasswordMutate.mutate(payload);
  };

  return {
    handleSubmit,
    loading: verifyForgotPasswordMutate.isLoading,
    error,
  };
};
