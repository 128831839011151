import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { GeneralErrorResponse } from 'types/auth';
import { userService } from 'services';
import { apiUtils, routerUtils } from 'utils';
import { useToastAlert } from 'components/Alerts';
import { pageRoutes } from 'variables';

export const useEditMember = (id?: string, isLoggedInUser?: boolean) => {
  const { triggerSuccessAlert, triggerErrorAlert } = useToastAlert();

  const editMemberMutate = useMutation(userService.editMember, {
    onSuccess: () => {
      triggerSuccessAlert({ message: 'Profile updated successfully!' });
      routerUtils.push(isLoggedInUser ? pageRoutes.PROFILE : `${pageRoutes.VIEW_USER_DETAIL}${id}`);
    },
    onError: (error: AxiosError<GeneralErrorResponse>) => {
      triggerErrorAlert({
        message: apiUtils.getAPIErrorMessage(error.response?.data?.message),
      });
    },
  });

  const handleSubmit = (payload: FormData) => {
    const data = {
      payload,
      id,
    };
    // @ts-expect-error
    editMemberMutate.mutate(data);
  };

  return {
    handleSubmit,
    loading: editMemberMutate.isLoading,
    success: editMemberMutate.isSuccess,
  };
};
