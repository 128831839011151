import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';

import { GeneralErrorResponse } from 'types/auth';
import { userService } from 'services';
import { apiUtils } from 'utils';
import { useToastAlert } from 'components/Alerts';

export const useAccountSetupInvite = ({ onSuccess }: { onSuccess?: () => void }) => {
  const [error, setError] = useState<string>('');
  const { triggerSuccessAlert, triggerErrorAlert } = useToastAlert();

  const inviteMemberMutate = useMutation(userService.inviteMember, {
    onSuccess: () => {
      triggerSuccessAlert({ message: 'Invite successfully sent!' });
      onSuccess?.();
    },
    onError: (error: AxiosError<GeneralErrorResponse>) => {
      const { message } = { ...error.response?.data };
      setError(apiUtils.getAPIErrorMessage(message));
      triggerErrorAlert({ message });
    },
  });

  const handleSubmit = (userId: string) => {
    setError('');
    inviteMemberMutate.mutate(userId);
  };

  return {
    handleSubmit,
    loading: inviteMemberMutate.isLoading,
    inviteError: error,
    success: inviteMemberMutate.isSuccess,
  };
};
