import { Container } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

import UserDetail from '../../Users/UserDetail';

import { useGetLoggedInUserInfo } from './useGetLoggedInUserInfo';

const UserProfileView = () => {
  const { user, loading } = useGetLoggedInUserInfo();

  return (
    <Container className="mt-6">
      {loading ? (
        <Skeleton count={5} height={50} className="mt-3" />
      ) : (
        user && <UserDetail showEditProfile isLoggedInUser user={user} />
      )}
    </Container>
  );
};

export default UserProfileView;
