import useGetUserPermissionConfig from './useGetUserPermissionConfig';

interface ProtectedViewProps {
  children: React.ReactNode;
  permission: string;
  permissionOveride?: boolean;
}

export const ProtectedView: React.FC<ProtectedViewProps> = ({ children, permission, permissionOveride }) => {
  const { userPermissions } = useGetUserPermissionConfig();
  return userPermissions[permission] || permissionOveride ? <>{children}</> : null;
};
