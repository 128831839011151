import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import LoginForm from './LoginForm';
import { useLogin } from './useLoginUser';

import { EmbeddedAlert } from 'components/Alerts';
import { AlertType } from 'components/Alerts/EmbeddedAlert';
import AuthHeader from 'components/Headers/AuthHeader';
import { pageRoutes } from 'variables/pageRoutes';
import { BRAND_IMAGES } from 'assets/img/brand';
import { constants } from 'variables';

const Login = () => {
  const { handleSubmit, loading, error } = useLogin();
  localStorage.removeItem(constants.RESET_PASSWORD_AUTH_TOKEN);

  return (
    <>
      <AuthHeader title="" lead="" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            {error && <EmbeddedAlert text={error} variant={AlertType.Danger} />}
            <Card className="bg-secondary border-0 mb-0">
              <div className="text-center mt-4">
                <img alt="..." src={BRAND_IMAGES.logoDefault} className="w-50" />
              </div>

              <CardBody className="px-lg-5 py-lg-5">
                <LoginForm handleSubmit={handleSubmit} loading={loading} />
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link className="text-light" to={pageRoutes.FORGOT_PASSWORD}>
                  <small>Forgot password?</small>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
