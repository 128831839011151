import { Button, UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';

import { ICON_IMAGES } from 'assets/img/icons';

interface DataTableErrorProps {
  isRefreshing?: boolean;
  onRefresh?: () => Promise<void>;
  error?: string | null;
  isEmptyError?: boolean | null;
}

const DataTableError: React.FC<DataTableErrorProps> = ({ error, onRefresh, isEmptyError, isRefreshing }) => {
  return !!error || (isEmptyError && !isRefreshing) ? (
    <div>
      <div className="text-center cursor-hover h3">
        <Button color="link" onClick={onRefresh} id="refresh-btn">
          <i className={classNames('fa fa-refresh text-lg', { 'fa-spin': isRefreshing })} />
        </Button>
        <UncontrolledTooltip delay={0} target="refresh-btn">
          Refresh data
        </UncontrolledTooltip>
      </div>
      <div className="text-center mx-auto mt-4" style={{ width: 60 }}>
        <img alt="..." src={ICON_IMAGES.dataNotFound} className="w-100" />
      </div>
      <h3 className="text-center text-danger mt-2">
        {error ?? isEmptyError ? 'No record found.' : 'An error occured. Please try again.'}
      </h3>
    </div>
  ) : null;
};

export default DataTableError;
