interface ModalCloseButtonProps {
  onClick: (e: boolean) => void;
}

const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({ onClick }) => {
  return (
    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => onClick(false)}>
      <span aria-hidden={true}>x</span>
    </button>
  );
};

export default ModalCloseButton;
