import { Col, FormGroup, Input } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';
import { UseFormRegisterReturn, UseFormRegister } from 'react-hook-form';
import isEmpty from 'lodash.isempty';

interface FormInputProps {
  value?: string | number;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type: InputType;
  name: string;
  label: string;
  block?: boolean;
  rows?: string;
  errorMessage?: string;
  errors?: any;
  id?: string;
  register?: UseFormRegister<any>;
  disabled?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  value,
  handleChange,
  placeholder,
  type,
  name,
  label,
  block,
  rows,
  errorMessage,
  errors,
  register,
  disabled,
  ...rest
}) => {
  const registerInnerRef = (fieldName: any, options = {}) => {
    if (!register) return;
    const registeredField: Partial<UseFormRegisterReturn> = register(fieldName, options);
    const ref = registeredField.ref;
    delete registeredField.ref;
    return { ...registeredField, innerRef: ref };
  };

  return (
    <Col md={block ? '12' : '6'}>
      <FormGroup>
        <label className="form-control-label text-capitalize">{label}</label>
        <Input
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          type={type}
          name={name}
          rows={rows}
          valid={Boolean(!errors?.[name]) && !isEmpty(errors)}
          invalid={errors?.[name]}
          disabled={disabled}
          {...registerInnerRef(name, { required: errorMessage })}
          {...rest}
        />
        {errors?.[name] && <p className="invalid-feedback">{errors[name]?.message}</p>}
      </FormGroup>
    </Col>
  );
};

export default FormInput;
