import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import ForgotPasswordForm from './ForgotPasswordForm';
import { useForgotPassword } from './useForgottenPassword';

import { EmbeddedAlert } from 'components/Alerts';
import { AlertType } from 'components/Alerts/EmbeddedAlert';
import AuthHeader from 'components/Headers/AuthHeader';
import { pageRoutes } from 'variables/pageRoutes';

const ForgotPassword = () => {
  const { handleSubmit, loading, error } = useForgotPassword();

  return (
    <>
      <AuthHeader title="Forgot Password?" lead="Enter your email address" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            {error && <EmbeddedAlert text={error} variant={AlertType.Danger} />}
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <ForgotPasswordForm handleSubmit={handleSubmit} loading={loading} />
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link className="text-light" to={pageRoutes.LOGIN}>
                  <small>Sign in</small>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
