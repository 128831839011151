import React from 'react';
import { FormGroup, Label, Input, Col } from 'reactstrap';
import { UseFormRegisterReturn, UseFormRegister } from 'react-hook-form';
import isEmpty from 'lodash.isempty';

interface DateDisplayProps {
  label: string;
  name: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  errorMessage?: string;
  errors?: any;
  id?: string;
  register?: UseFormRegister<any>;
  block?: boolean;
}

const DateDisplay: React.FC<DateDisplayProps> = ({
  handleChange,
  label,
  name,
  value,
  errorMessage,
  errors,
  block,
  register,
  ...rest
}) => {
  const registerInnerRef = (fieldName: any, options = {}) => {
    if (!register) return;
    const registeredField: Partial<UseFormRegisterReturn> = register(fieldName, options);
    const ref = registeredField.ref;
    delete registeredField.ref;
    return { ...registeredField, innerRef: ref };
  };
  return (
    <Col md={block ? '12' : '6'}>
      <FormGroup>
        <Label className="form-control-label"> {label}</Label>
        <Input
          name={name}
          type="date"
          onChange={handleChange}
          value={value}
          valid={Boolean(!errors?.[name]) && !isEmpty(errors)}
          invalid={errors?.[name]}
          {...registerInnerRef(name, { required: errorMessage })}
          {...rest}
        />
        {errors?.[name] && <p className="invalid-feedback">{errors[name]?.message}</p>}
      </FormGroup>
    </Col>
  );
};

export default DateDisplay;
