import React from 'react';
import { Container, Card, CardBody, Row, Col, Button } from 'reactstrap';

import { useCompleteAccountSetup } from './useCompleteAccountSetup';

import { useSearchQuery } from 'hooks/useSearchQuery';
import { EmbeddedAlert } from 'components/Alerts';
import { AlertType } from 'components/Alerts/EmbeddedAlert';
import { FormInput } from 'components/Forms';
import { StaffPasswordData } from 'types/user';
import { constants } from 'variables';
import AuthHeader from 'components/Headers/AuthHeader';
import { BRAND_IMAGES } from 'assets/img/brand';

const defaultStaffPassword = {
  password: '',
  confirmPassword: '',
};

const CompleteAccountSetup = () => {
  const [formData, setFormData] = React.useState<StaffPasswordData>(defaultStaffPassword);
  const [passwordError, setPasswordError] = React.useState<string>('');

  const query = useSearchQuery();
  const token = query.get('token');
  localStorage.setItem(constants.AUTH_TOKEN, token as string);

  const { error, success, loading, handleSubmit } = useCompleteAccountSetup();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNewPasswordSubmit = (payload: StaffPasswordData) => {
    setPasswordError('');
    const pattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[a-zA-Z\d]{6,})(?=.*[^a-zA-Z\d]).*$/;
    const isMatch = pattern.test(payload.password);
    if (!isMatch) {
      setPasswordError('Password should be a minimum of 6 digits, at least a capital letter and a special character');
      return;
    }
    if (payload.password !== payload.confirmPassword) {
      setPasswordError('Passwords do not match!');
      return;
    }
    handleSubmit({ password: payload.password });
  };

  return (
    <>
      <AuthHeader
        title="Complete Your Account Setup"
        lead="Set your password below to complete your account setup. Password must be a  minimum of 6 digits, at least a capital letter and a special character."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            {(error || passwordError) && <EmbeddedAlert text={error || passwordError} variant={AlertType.Danger} />}
            {success && <EmbeddedAlert text={'success'} variant={AlertType.Success} />}
            <Card className="bg-secondary border-0 mb-0">
              <div className="text-center mt-4">
                <img alt="..." src={BRAND_IMAGES.logoDefault} className="w-25" />
              </div>
              <CardBody className="d-flex justify-content-center flex-column pr-5 pl-5">
                <Row>
                  <FormInput
                    value={formData.password}
                    handleChange={handleChange}
                    type="password"
                    name="password"
                    label="Password"
                    block={true}
                  />
                </Row>
                <Row>
                  <FormInput
                    value={formData.confirmPassword}
                    handleChange={handleChange}
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    block={true}
                  />
                </Row>
                <Row className="mx-0 mt-4 justify-content-center">
                  <Button
                    color="primary"
                    type="button"
                    size="xl"
                    disabled={loading || !formData.confirmPassword || !formData.password}
                    style={{ width: '200px' }}
                    onClick={() => handleNewPasswordSubmit(formData)}
                  >
                    {loading ? 'submitting...' : 'submit'}
                  </Button>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CompleteAccountSetup;
