import { Card, CardHeader, Table, Container, CardFooter } from 'reactstrap';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';

import { useGetAllAuditLogs } from './useGetAllAuditLogs';

import AdminHeader from 'components/Headers/AdminHeader';
import { DataTableError } from 'components/DataTableError';
import { Pagination } from 'components/Pagination';
import { useFilterParams } from 'hooks/useFilterParams';
import { PageFilters } from 'components/PageFilter';
import { FilterEnum } from 'components/PageFilter/PageFilters';
import { constants } from 'variables';

const ViewAllAuditLogs = () => {
  const { params, paramsString, setParams } = useFilterParams();
  const { allAuditLogs, error, loading, refetch, count, pages } = useGetAllAuditLogs(paramsString);
  const [showFilter, setShowFilter] = useState(false);

  return (
    <>
      <AdminHeader pageTitle="Audit Logs" showFilters={showFilter} />
      <Container className="mt--6" fluid>
        <>
          {showFilter && (
            <PageFilters
              filters={[
                FilterEnum.USERS,
                FilterEnum.SEARCH_INPUT,
                FilterEnum.VERIFICATION_STATUS,
                FilterEnum.IS_SUPERUSER,
              ]}
              setParams={setParams}
              setShowPageFilter={() => setShowFilter(!showFilter)}
            />
          )}
        </>
        <Card>
          <CardHeader className="border-0">
            <DataTableError
              isEmptyError={!allAuditLogs?.length}
              error={error?.message}
              isRefreshing={loading}
              onRefresh={refetch}
            />
            {loading && <Skeleton count={5} height={50} className="mt-3" />}
          </CardHeader>
          {!!allAuditLogs?.length && (
            <Table className="align-items-center table-flush" responsive striped hover>
              <thead className="thead-light">
                <tr>
                  <th>Date</th>
                  <th>Action Type</th>
                  <th>User Email</th>
                  <th>IP Address</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {allAuditLogs.map((i) => {
                  return (
                    <tr key={i.id} className="cursor-hover">
                      <td className="table-user text-capitalize">
                        {`${dayjs(i.createdAt).format(constants.TWELVE_HR_DATE_FMT)}`}
                      </td>
                      <td>{i.actionType}</td>
                      <td>{i.userEmail || 'N/A'}</td>
                      <td>{i.ipAddress}</td>
                      <td>{i.description}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          {pages && count && pages > 1 && (
            <CardFooter>
              <Pagination
                totalCount={count}
                pageCount={pages}
                onChange={(page) => setParams({ page })}
                page={params.page}
              />
            </CardFooter>
          )}
        </Card>
      </Container>
    </>
  );
};

export default ViewAllAuditLogs;
