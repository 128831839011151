import axios from 'axios';

import storageUtils from './storageUtils';
import authUtils from './authUtils';

import { constants } from 'variables';
import { MultiplePostRequestData } from 'types/index';

const IPAddress = storageUtils.getFromLocalStorage(constants.IP_ADDRESS);

const defaultAxiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
    'X-Network-Address': IPAddress || undefined,
  },
};

const axiosMultiPartConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

axios.interceptors.request.use(
  (config) => {
    const token = storageUtils.getFromLocalStorage(constants.AUTH_TOKEN);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  async (error) => {
    return await Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      authUtils.logoutUser();
    }
    return await Promise.reject(error);
  },
);

const apiUtils = {
  getRequest: async (url: string) => {
    const res = await axios.get(`${constants.V1_BASE_API_URL}${url}`, axiosConfig);
    return res;
  },
  getRequestWithFullAPIUrl: async (url: string) => {
    const res = await axios.get(url, defaultAxiosConfig);
    return res;
  },
  postRequest: async (url: string, payload: any) => {
    const res = await axios.post(`${constants.V1_BASE_API_URL}${url}`, payload, axiosConfig);
    return res;
  },
  postFormDataRequest: async (url: string, payload: any) => {
    const res = await axios.post(`${constants.V1_BASE_API_URL}${url}`, payload, axiosMultiPartConfig);
    return res;
  },
  putRequest: async (url: string, payload: any) => {
    const res = await axios.put(`${constants.V1_BASE_API_URL}${url}`, payload, axiosConfig);
    return res;
  },
  getMultipleRequests: async (urls: string[]) => {
    const requests = urls.map(
      async (url: string) => await axios.get(`${constants.V1_BASE_API_URL}${url}`, axiosConfig).catch((error) => error),
    );
    return await axios.all(requests).then(
      axios.spread((...responses) => {
        return responses.map(({ data }) => data);
      }),
    );
  },
  postMultipleRequests: async (postData: MultiplePostRequestData[]) => {
    const requests = postData.map(
      async ({ url, payload }) =>
        await axios.post(`${constants.V1_BASE_API_URL}${url}`, payload, axiosConfig).catch((error) => error),
    );
    return await axios.all(requests).then(
      axios.spread((...responses) => {
        return responses.map(({ data }) => data);
      }),
    );
  },
  getAPIErrorMessage: (error?: string) => {
    if (error) {
      return error;
    } else {
      return 'An error occured. Please try again.';
    }
  },
};

export default apiUtils;
