import routerUtils from './routerUtils';

import { constants, pageRoutes } from 'variables';

const authUtils = {
  checkUserAuth: () => {
    const token = localStorage.getItem(constants.AUTH_TOKEN);
    return Boolean(token);
  },

  logoutUser: () => {
    localStorage.removeItem(constants.AUTH_TOKEN);
    localStorage.removeItem(constants.USER_INFO);
    localStorage.removeItem(constants.USER_PERMISSIONS);
    localStorage.removeItem(constants.IP_ADDRESS);
    routerUtils.push(pageRoutes.LOGIN);
    // window.location.reload();
  },

  getUserInfo: () => {
    const user = localStorage.getItem(constants.USER_INFO);
    return user ? JSON.parse(user) : {};
  },
};

export default authUtils;
