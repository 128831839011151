import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { userService } from 'services';
import { AllRoles, AllRolesError } from 'types/users';
import { dropdownUtils } from 'utils';
import { queryKeys } from 'variables';

export const useViewAllRoles = (paramsString: string) => {
  const { data, error, isLoading, refetch } = useQuery<AxiosResponse<AllRoles>, AxiosError<AllRolesError>>(
    queryKeys.ALL_ROLES,
    () => userService.getAllRoles(paramsString || ''),
  );

  return {
    allRoles: data?.data.data,
    rolesDropdownOptions: dropdownUtils.formatRolesDropdownOptions(data?.data.data),
    loading: isLoading,
    refetch: async () => {
      await refetch();
    },
    error,
  };
};
