import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import convertToCamelCase from 'lodash.camelcase';
import { useState } from 'react';

import CreateConfigModal from './CreateConfigModal';
import { useGetConfigs } from './hooks/useGetConfigs';
import { CreateConfigTypes } from './hooks/useCreateConfig';
import EditConfigModal from './EditConfigModal';

import AdminHeader from 'components/Headers/AdminHeader';
import { DataTableError } from 'components/DataTableError';
import { ButtonWithTooltip } from 'components/Buttons';
import { ConfigData } from 'types/config';

const ConfigView: React.FC<{ configType: CreateConfigTypes }> = ({ configType }) => {
  const { data, error, loading, refetch } = useGetConfigs(configType);

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedConfig, setSelectedConfig] = useState<ConfigData>({ id: '', name: '' });

  return (
    <>
      {openCreateModal && (
        <CreateConfigModal open={openCreateModal} setOpenModal={setOpenCreateModal} configType={configType} />
      )}
      {openEditModal && selectedConfig && (
        <EditConfigModal
          data={selectedConfig}
          open={openEditModal}
          setOpenModal={setOpenEditModal}
          configType={configType}
        />
      )}
      <AdminHeader pageTitle={configType} />
      <Container className="mt--6">
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col className="text-right offset-6" xs="6">
                <ButtonWithTooltip
                  id="createNewConfig"
                  onClick={() => setOpenCreateModal(true)}
                  tooltipText={`Create new ${configType}`}
                  iconClassName="fa fa-add"
                >
                  {`Create ${configType}`}
                </ButtonWithTooltip>
              </Col>
            </Row>
            <DataTableError
              isEmptyError={!data?.length}
              error={error?.message}
              isRefreshing={loading}
              onRefresh={refetch}
            />
            {loading && <Skeleton count={5} height={50} className="mt-3" />}
          </CardHeader>

          {!!data?.length && (
            <Table className="align-items-center table-flush" responsive striped>
              <thead className="thead-light">
                <tr>
                  <th>S/N</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((i, index) => (
                  <tr key={i.id}>
                    <td>{Number(index) + 1}</td>
                    <td className="table-user">
                      <b>{i.name}</b>
                    </td>
                    <td>{i?.description ?? 'N/A'}</td>
                    <td>
                      <Button color="link" id={convertToCamelCase(`${i.name}`)}>
                        <i className="fas fa-ellipsis-v" />
                      </Button>
                      <UncontrolledPopover placement="left" target={convertToCamelCase(`${i.name}`)} trigger="legacy">
                        <PopoverHeader>
                          <small className="text-center">{i.name}</small>
                        </PopoverHeader>
                        <PopoverBody>
                          <ListGroup flush>
                            <ListGroupItem
                              tag="button"
                              onClick={() => {
                                setSelectedConfig(i);
                                setOpenEditModal(true);
                              }}
                            >
                              <i className="fas fa-user-edit text-primary pr-3" />
                              Edit
                            </ListGroupItem>
                          </ListGroup>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card>
      </Container>
    </>
  );
};

export default ConfigView;
