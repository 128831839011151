export const permissionCodes = {
  CAN_VIEW_AUDIT_LOGS: 'can_view_audit_logs',
  CAN_SET_USER_ROLE: 'can_set_user_role',
  CAN_ADD_MEMBERS: 'can_add_members',
  CAN_VIEW_MEMBER_DATE_OF_BIRTH: 'can_view_member_date_of_birth',
  CAN_VIEW_MEMBER_NEXT_OF_KIN: 'can_view_member_next_of_kin',
  CAN_MODIFY_CONFIGURATIONS: 'can_modify_configurations',
  CAN_VIEW_CONFIGURATIONS: 'can_view_configurations',
  CAN_EDIT_MEMBERS: 'can_edit_members',
  CAN_VIEW_BAPTISM_INFORMATION: 'can_view_baptism_information',
  CAN_SEND_ACCOUNT_INVITE: 'can_set_account_invite',
};

export const permissionsConfig = [
  {
    id: 1,
    code: permissionCodes.CAN_VIEW_AUDIT_LOGS,
    name: 'CAN VIEW AUDIT LOGS',
  },
  {
    id: 2,
    code: permissionCodes.CAN_SET_USER_ROLE,
    name: 'CAN SET USER ROLE',
  },
  {
    id: 3,
    code: permissionCodes.CAN_ADD_MEMBERS,
    name: 'CAN ADD MEMBERS',
  },
  {
    id: 4,
    code: permissionCodes.CAN_VIEW_MEMBER_DATE_OF_BIRTH,
    name: 'CAN VIEW MEMBER DATE OF BIRTH',
  },
  {
    id: 5,
    code: permissionCodes.CAN_VIEW_MEMBER_NEXT_OF_KIN,
    name: 'CAN VIEW MEMBER NEXT OF KIN',
  },
  {
    id: 6,
    code: permissionCodes.CAN_MODIFY_CONFIGURATIONS,
    name: 'CAN MODIFY CONFIGURATIONS',
  },
  {
    id: 7,
    code: permissionCodes.CAN_VIEW_CONFIGURATIONS,
    name: 'CAN VIEW CONFIGURATIONS',
  },
  {
    id: 8,
    code: permissionCodes.CAN_EDIT_MEMBERS,
    name: 'CAN EDIT MEMBERS',
  },
  {
    id: 9,
    code: permissionCodes.CAN_VIEW_BAPTISM_INFORMATION,
    name: 'CAN VIEW BAPTISM INFORMATION',
  },
  {
    id: 10,
    code: permissionCodes.CAN_SEND_ACCOUNT_INVITE,
    name: 'CAN SEND ACCOUNT INVITE',
  },
];
