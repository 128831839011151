import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';

import { authService } from 'services';
import { ChangePasswordErrorResponse, ChangePasswordPayload } from 'types/auth';
import { apiUtils, authUtils } from 'utils';
import { useToastAlert } from 'components/Alerts';

export const useChangePassword = () => {
  const [error, setError] = useState<string>('');
  const { triggerSuccessAlert } = useToastAlert();

  const changePasswordMutate = useMutation(authService.changePassword, {
    onSuccess: () => {
      authUtils.logoutUser();
      triggerSuccessAlert({ title: 'Password changed successfully!', message: 'Login to continue.' });
    },
    onError: (error: AxiosError<ChangePasswordErrorResponse>) => {
      const { response } = error;
      setError(apiUtils.getAPIErrorMessage(response?.data.message));
    },
  });

  const handleSubmit = (payload: ChangePasswordPayload) => {
    setError('');
    const { currentPassword, newPassword } = payload;
    changePasswordMutate.mutate({ currentPassword, newPassword });
  };

  return {
    handleSubmit,
    loading: changePasswordMutate.isLoading,
    error,
    success: changePasswordMutate.isSuccess,
  };
};
