import { Container } from 'reactstrap';

import EldersCard from './EldersCard';
import DeaconsCard from './DeaconsCard';
import BibleQuoteCard from './BibleQuoteCard';
import { useGetOverviewData } from './useGetOverviewData';
import PreachersCard from './PreachersCard';

import CardsHeader from 'components/Headers/CardsHeader';

const AdminDashboard = () => {
  const { overview, loading } = useGetOverviewData();
  return (
    <>
      <CardsHeader name="Overview" parentName="Dashboard" data={overview} loading={loading} />
      <Container fluid>
        <div className="card-deck flex-column flex-xl-row" style={{ marginTop: '-50px' }}>
          <EldersCard data={overview?.elders} loading={loading} />
          <DeaconsCard data={overview?.deacons} loading={loading} />
          <PreachersCard data={overview?.preachers} loading={loading} />
          <BibleQuoteCard />
        </div>
      </Container>
    </>
  );
};

export default AdminDashboard;
