import dataNotFound from 'assets/img/icons/common/data-not-found.png';
import bitcoin from 'assets/img/icons/cards/bitcoin.png';
import mastercard from 'assets/img/icons/cards/mastercard.png';
import paypal from 'assets/img/icons/cards/paypal.png';
import visa from 'assets/img/icons/cards/visa.png';
import flagDE from 'assets/img/icons/flags/DE.png';
import flagUS from 'assets/img/icons/flags/US.png';
import flagGB from 'assets/img/icons/flags/GB.png';
import imageNotFound from 'assets/img/icons/common/image-not-found.jpeg';
import defaultImage from 'assets/img/icons/common/default-image.jpeg';
import officialBadge from 'assets/img/icons/common/official-badge.png';

export const ICON_IMAGES = {
  bitcoin,
  mastercard,
  paypal,
  visa,
  flagDE,
  flagGB,
  flagUS,
  dataNotFound,
  imageNotFound,
  defaultImage,
  officialBadge,
};
