import { Layout } from 'types/index';
import Buttons from 'views/sample-pages/components/Buttons';
import Cards from 'views/sample-pages/components/Cards';
import Filters from 'views/sample-pages/components/Filters';
import Grid from 'views/sample-pages/components/Grid';
import Icons from 'views/sample-pages/components/Icons';
import Notifications from 'views/sample-pages/components/Notifications';
import Typography from 'views/sample-pages/components/Typography';
import DashboardSample2 from 'views/sample-pages/dashboards/Alternative';
import DashboardSample1 from 'views/sample-pages/dashboards/Dashboard';
import Components from 'views/sample-pages/forms/Components';
import Elements from 'views/sample-pages/forms/Elements';
import Validation from 'views/sample-pages/forms/Validation';
import ReactBSTables from 'views/sample-pages/tables/ReactBSTables';
import Sortable from 'views/sample-pages/tables/Sortable';
import Tables from 'views/sample-pages/tables/Tables';
import Widgets from 'views/sample-pages/Widgets';

const devDocsRoutes = {
  collapse: true,
  name: 'Dev Docs',
  icon: 'ni ni-folder-17',
  state: 'eventsCollapse',
  views: [
    {
      path: '/components/buttons',
      name: 'Buttons',
      miniName: 'Com..',
      component: Buttons,
      layout: Layout.Admin,
    },
    {
      path: '/components/cards',
      name: 'Cards',
      miniName: 'Com..',
      component: Cards,
      layout: Layout.Admin,
    },
    {
      path: '/components/filters',
      name: 'Filters',
      miniName: 'Fil..',
      component: Filters,
      layout: Layout.Admin,
    },
    {
      path: '/components/grid',
      name: 'Grid',
      miniName: 'Com..',
      component: Grid,
      layout: Layout.Admin,
    },
    {
      path: '/components/icons',
      name: 'Icons',
      miniName: 'Com..',
      component: Icons,
      layout: Layout.Admin,
    },
    {
      path: '/components/notifications',
      name: 'Notifications',
      miniName: 'Com..',
      component: Notifications,
      layout: Layout.Admin,
    },
    {
      path: '/components/typography',
      name: 'Typography',
      miniName: 'Com..',
      component: Typography,
      layout: Layout.Admin,
    },
    {
      path: '/components/dashboard-1',
      name: 'Dashboard 1',
      miniName: 'Com..',
      component: DashboardSample1,
      layout: Layout.Admin,
    },
    {
      path: '/components/dashboard-2',
      name: 'Dashboard 2',
      miniName: 'Com..',
      component: DashboardSample2,
      layout: Layout.Admin,
    },
    {
      path: '/components/form-elements',
      name: 'Form Elements',
      miniName: 'Com..',
      component: Elements,
      layout: Layout.Admin,
    },
    {
      path: '/components/form-validation',
      name: 'From Validation',
      miniName: 'Com..',
      component: Validation,
      layout: Layout.Admin,
    },
    {
      path: '/components/form-components',
      name: 'From Components',
      miniName: 'Com..',
      component: Components,
      layout: Layout.Admin,
    },
    {
      path: '/components/tables-1',
      name: 'Tables',
      miniName: 'Com..',
      component: Tables,
      layout: Layout.Admin,
    },
    {
      path: '/components/tables-2',
      name: 'BS Tables',
      miniName: 'Com..',
      component: ReactBSTables,
      layout: Layout.Admin,
    },
    {
      path: '/components/tables-3',
      name: 'Sortable Tables',
      miniName: 'Com..',
      component: Sortable,
      layout: Layout.Admin,
    },
    {
      path: '/components/widgets',
      name: 'Widgets',
      miniName: 'Com..',
      component: Widgets,
      layout: Layout.Admin,
    },
  ],
};

export default devDocsRoutes;
