import { ConfigData } from 'types/config';
import { apiUtils } from 'utils';
import { api } from 'variables';

const configService = {
  createFamily: async (payload: { name: string }) => {
    return await apiUtils.postRequest(api.CREATE_FAMILY, payload);
  },
  createMinistry: async (payload: { name: string }) => {
    return await apiUtils.postRequest(api.CREATE_MINISTRY, payload);
  },
  createZone: async (payload: { name: string }) => {
    return await apiUtils.postRequest(api.CREATE_ZONE, payload);
  },
  editFamily: async (payload: ConfigData) => {
    return await apiUtils.postRequest(`${api.EDIT_FAMILY}/${payload.id}`, payload);
  },
  editMinistry: async (payload: ConfigData) => {
    return await apiUtils.postRequest(`${api.EDIT_MINISTRY}/${payload.id}`, payload);
  },
  editZone: async (payload: ConfigData) => {
    return await apiUtils.postRequest(`${api.EDIT_ZONE}/${payload.id}`, payload);
  },
  getAllFamilies: async () => {
    return await apiUtils.getRequest(api.GET_ALL_FAMILIES);
  },
  getAllZones: async () => {
    return await apiUtils.getRequest(api.GET_ALL_ZONES);
  },
  getAllMinistries: async () => {
    return await apiUtils.getRequest(api.GET_ALL_MINISTRIES);
  },
};

export default configService;
