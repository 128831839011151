import { Layout } from 'types/index';
import { pageRoutes } from 'variables/pageRoutes';
import { CompleteAccountSetup } from 'views/pages/Auth/CompleteAccountSetup';
import { ForgotPassword, SetNewPassword } from 'views/pages/Auth/ForgotPassword';
import VerifyForgotPassword from 'views/pages/Auth/ForgotPassword/VerifyForgotPassword';
import { Login } from 'views/pages/Auth/Login';

const authRoutes = [
  {
    path: pageRoutes.LOGIN,
    component: Login,
    layout: Layout.Auth,
  },
  {
    path: pageRoutes.FORGOT_PASSWORD,
    component: ForgotPassword,
    layout: Layout.Auth,
  },
  {
    path: pageRoutes.COMPLETE_ACCOUNT_SETUP,
    component: CompleteAccountSetup,
    layout: Layout.Auth,
  },
  {
    path: pageRoutes.FORGOT_PASSWORD_VERIFICATION,
    component: VerifyForgotPassword,
    layout: Layout.Auth,
  },
  {
    path: pageRoutes.SET_NEW_PASSWORD,
    component: SetNewPassword,
    layout: Layout.Auth,
  },
];

export default authRoutes;
