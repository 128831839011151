import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader';
import { Roles, Users } from 'components/Filters';
import { FilterEnum, PageFilters } from 'components/PageFilter/PageFilters';

const Filters = () => {
  return (
    <>
      <SimpleHeader name="Filters" parentName="Components" />
      <Container fluid className="mt--6">
        <Row className="justify-center">
          <Card className="w-100">
            <CardHeader>
              <h3>Filters</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="12" md="4">
                  <Users onChange={() => {}} />
                </Col>
                <Col sm="12" md="4">
                  <Roles onChange={() => {}} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
        <Row>
          <PageFilters filters={[FilterEnum.ROLES, FilterEnum.USERS]} />
        </Row>
      </Container>
    </>
  );
};

export default Filters;
