import { apiUtils } from 'utils';
import { api } from 'variables';
import { ForgotPasswordPayload, LoginPayload, ChangePasswordPayload, SetNewPasswordPayload } from 'types/auth';

const authService = {
  loginUser: async (payload: LoginPayload) => {
    return await apiUtils.postRequest(api.LOGIN, payload);
  },
  forgotPassword: async (payload: ForgotPasswordPayload) => {
    return await apiUtils.postRequest(api.FORGET_PASSWORD, payload);
  },
  verifyForgotPassword: async (payload: ForgotPasswordPayload) => {
    return await apiUtils.postRequest(api.FORGOT_PASSWORD_VERIFY_OTP, payload);
  },
  changePassword: async (payload: ChangePasswordPayload) => {
    return await apiUtils.postRequest(api.CHANGE_PASSWORD, payload);
  },
  setNewPassword: async (payload: SetNewPasswordPayload) => {
    return await apiUtils.postRequest(api.SET_NEW_PASSWORD, payload);
  },
};

export default authService;
