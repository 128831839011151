import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { userService } from 'services';
import { AllUsers, AllUsersError } from 'types/users';
import { dropdownUtils } from 'utils';
import { queryKeys } from 'variables';

export const useGetAllUsers = (paramsString?: string, page?: number) => {
  const { data, error, isLoading, refetch } = useQuery<AxiosResponse<AllUsers>, AxiosError<AllUsersError>>(
    [queryKeys.ALL_USERS, paramsString],
    () => userService.getAllUsers(paramsString || ''),
  );

  return {
    allUsers: data?.data.data,
    count: data?.data.totalCount,
    pages: data?.data.totalPages,
    usersDropdownOptions: dropdownUtils.formatUsersDropdownOptions(data?.data.data),
    loading: isLoading,
    refetch: async () => {
      await refetch();
    },
    error,
  };
};
