import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';

import { authService } from 'services';
import { SetNewPasswordPayload, SetNewPasswordResponse } from 'types/auth';
import { apiUtils, routerUtils } from 'utils';
import { pageRoutes } from 'variables';
import { useToastAlert } from 'components/Alerts';

export const useSetNewPassword = () => {
  const [error, setError] = useState<string>('');
  const { triggerSuccessAlert } = useToastAlert();

  const setNewPasswordMutate = useMutation(authService.setNewPassword, {
    onSuccess: (res: AxiosResponse<SetNewPasswordPayload>) => {
      routerUtils.push(`${pageRoutes.LOGIN}`);
      triggerSuccessAlert({ message: 'Password changed successfully!' });
    },
    onError: (error: AxiosError<SetNewPasswordResponse>) => {
      const { response } = error;
      setError(apiUtils.getAPIErrorMessage(response?.data.message));
    },
  });

  const handleSubmit = (payload: SetNewPasswordPayload) => {
    setError('');
    setNewPasswordMutate.mutate(payload);
  };

  return {
    handleSubmit,
    loading: setNewPasswordMutate.isLoading,
    error,
  };
};
