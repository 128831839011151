import classNames from 'classnames';
import { UncontrolledAlert } from 'reactstrap';

export enum AlertType {
  Default = 'default',
  Primary = 'primary',
  Secondary = 'secondary',
  Info = 'info',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
}

interface EmbeddedAlertsProps {
  variant?: AlertType;
  text: string;
  boldText?: boolean;
}

const EmbeddedAlert: React.FC<EmbeddedAlertsProps> = ({ variant = AlertType.Default, text, boldText }) => {
  const alertClassNames = classNames({
    'alert-default': variant === AlertType.Default,
    'alert-primary': variant === AlertType.Primary,
    'alert-secondary': variant === AlertType.Secondary,
    'alert-info': variant === AlertType.Info,
    'alert-success': variant === AlertType.Success,
    'alert-danger': variant === AlertType.Danger,
    'alert-warning': variant === AlertType.Warning,
  });

  return (
    <UncontrolledAlert className={alertClassNames}>
      <span className="alert-text ml-1">{boldText ? <strong>{text}</strong> : <>{text}</>}</span>
    </UncontrolledAlert>
  );
};

export default EmbeddedAlert;
