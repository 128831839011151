import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  ListGroup,
  ListGroupItem,
  UncontrolledPopover,
  PopoverBody,
  CardFooter,
} from 'reactstrap';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import convertToCamelCase from 'lodash.camelcase';
import { Link } from 'react-router-dom';

import { useGetAllUsers } from './hooks/useGetAllUsers';

import AdminHeader from 'components/Headers/AdminHeader';
import { DataTableError } from 'components/DataTableError';
import { pageRoutes } from 'variables';
import { routerUtils, authUtils } from 'utils';
import { Pagination } from 'components/Pagination';
import { useFilterParams } from 'hooks/useFilterParams';
import { PageFilters } from 'components/PageFilter';
import { FilterEnum } from 'components/PageFilter/PageFilters';
import { ProtectedView } from 'components/ProtectedView/ProtectedView';
import useGetUserPermissionConfig from 'components/ProtectedView/useGetUserPermissionConfig';

const accountStatusColors = {
  PENDING: 'warning',
  INVITED: 'info',
  ACTIVATED: 'success',
  DEACTIVATED: 'danger',
};

const ViewAllUsers = () => {
  const { params, paramsString, setParams } = useFilterParams();
  const { allUsers, error, loading, refetch, count, pages } = useGetAllUsers(paramsString);
  const [showFilter, setShowFilter] = useState(false);
  const { codes } = useGetUserPermissionConfig();

  const user = authUtils.getUserInfo();

  return (
    <>
      <AdminHeader pageTitle="All Users" showFilters={showFilter} />
      <Container className="mt--6" fluid>
        <>
          {showFilter && (
            <PageFilters
              filters={[
                FilterEnum.USERS,
                FilterEnum.SEARCH_INPUT,
                FilterEnum.VERIFICATION_STATUS,
                FilterEnum.IS_SUPERUSER,
              ]}
              setParams={setParams}
              setShowPageFilter={() => setShowFilter(!showFilter)}
            />
          )}
        </>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <ProtectedView permission={codes.CAN_ADD_MEMBERS}>
                <Col className="text-right offset-6" xs="6">
                  <UncontrolledTooltip delay={0} target="create-member">
                    Add member record
                  </UncontrolledTooltip>
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    id="create-member"
                    onClick={(e) => routerUtils.push(pageRoutes.CREATE_MEMBER)}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-user-plus" />
                    </span>
                    <span className="btn-inner--text">Add member</span>
                  </Button>
                </Col>
              </ProtectedView>
            </Row>
            <DataTableError
              isEmptyError={!allUsers?.length}
              error={error?.message}
              isRefreshing={loading}
              onRefresh={refetch}
            />
            {loading && <Skeleton count={5} height={50} className="mt-3" />}
          </CardHeader>
          {!!allUsers?.length && (
            <Table className="align-items-center table-flush" responsive striped hover>
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Account Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.map((i) => {
                  return (
                    <tr key={i.id} className="cursor-hover">
                      <td
                        className="table-user text-capitalize"
                        onClick={() => routerUtils.push(`${pageRoutes.VIEW_USER_DETAIL}${i.id}`)}
                      >
                        <b>{`${i.firstName} ${i.lastName}`}</b>
                      </td>
                      <td onClick={() => routerUtils.push(`${pageRoutes.VIEW_USER_DETAIL}${i.id}`)}>{i.email}</td>
                      <td onClick={() => routerUtils.push(`${pageRoutes.VIEW_USER_DETAIL}${i.id}`)}>
                        {i?.membershipInfo?.phoneNumber || 'N/A'}
                      </td>
                      <td onClick={() => routerUtils.push(`${pageRoutes.VIEW_USER_DETAIL}${i.id}`)}>
                        <Badge color={accountStatusColors[String(i.accountStatus)]}>{i.accountStatus}</Badge>
                      </td>
                      <td>
                        <Button color="link" id={convertToCamelCase(`${i.firstName}${i.lastName}`)}>
                          <i className="fas fa-ellipsis-v" />
                        </Button>
                        <UncontrolledPopover
                          placement="left"
                          target={convertToCamelCase(`${i.firstName}${i.lastName}`)}
                          trigger="legacy"
                        >
                          <PopoverBody>
                            <ListGroup flush>
                              <ListGroupItem>
                                <Link to={`${pageRoutes.VIEW_USER_DETAIL}${i.id}`}>
                                  <i className="fas fa-eye text-success pr-3" />
                                  Veiw User Details
                                </Link>
                              </ListGroupItem>
                              {i.enabled && user.isSuperuser && (
                                <ListGroupItem>
                                  <Link
                                    to={`#`}
                                    // onClick={() => handleQuestionTriggerAlert(i.id, `${i.firstName} ${i.lastName}`)}
                                  >
                                    <i className="fas fa-check-double text-success pr-3" />
                                    Deactivate Account
                                  </Link>
                                </ListGroupItem>
                              )}
                            </ListGroup>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          {pages && count && pages > 1 && (
            <CardFooter>
              <Pagination
                totalCount={count}
                pageCount={pages}
                onChange={(page) => setParams({ page })}
                page={params.page}
              />
            </CardFooter>
          )}
        </Card>
      </Container>
    </>
  );
};

export default ViewAllUsers;
